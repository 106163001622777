import Vue from "vue";
import router from "@/router/router";
import { Loading, Message } from "element-ui";
import http from "@/utils/api/http";
let baseURL = http.baseURL;
let that = Vue.prototype;

export default {
  // 配置参数
  configData: {
    loadText: {
      loadmore: "点击加载更多",
      loading: "努力加载中",
      nomore: "没有更多数据了",
    },
    baseURL: baseURL, //线上
    imgUrl: "",
    isIphoneX: false,
    customerUrl: "http://api.idr.cn/",
  },
  // 提示信息
  Toast: (msg) => {
    msg;
  },
  // 跳转页面路径
  goUrl: (url) => {
    if (url) {
      router.push({ path: url });
    } else {
      that.$message.error("路由参数错误");
    }
  },
  // 返回上一页
  backPage: () => {
    router.go(-1);
  },
  // 跳转外联
  openUrl(item) {
    // let i = (index + 1) / this.listNum - 1
    if (item.url) {
      if (item.url_type == 0) {
        let type = item.discover_type;
        let url;
        if (type == 3) {
          // 视频
          url = "/found-details-videoDetails?id=" + item.url;
        } else if (type == 5) {
          // 问答
          url = "/found-qa?id=" + item.url;
        } else if (type) {
          url = "/found-details-details?id=" + item.url;
        }
        if (url) that.xin.goUrl(url);
      } else {
        window.open(item.url);
      }
    }
  },
  // loading层
  isLoadding(flag) {
    if (flag) {
      Loading.service({ fullscreen: true });
    } else {
      Loading.service({ fullscreen: true }).close();
    }
  },
  // 提示消息
  message(message, type = "suc") {
    switch (type) {
      case "suc":
        Message({ showClose: true, message, type: "success" });
        break;
      case "war":
        Message({ showClose: true, message, type: "error" });
        break;
      case "err":
        Message({ showClose: true, message, type: "error" });
        break;
    }
  },
  //内链发现
  async goFind(item) {
    if (item.url) {
      if (item.url_type == 0) {
        //内
        let urls;
        that.xin.isLoadding(true);
        let { code, data, message } = await that.$findApi.findDetail({
          discover_id: item.url,
        });
        that.xin.isLoadding();
        if (code != 200) {
          return;
        }
        let type = data.info.type;
        if (type == 3) {
          // 视频
          urls = "/found-details-videoDetails?id=" + item.url;
        } else if (type == 5) {
          // 问答
          urls = "/found-qa?id=" + item.url;
        } else {
          urls = "/found-details-details?id=" + item.url;
        }
        if (urls) that.xin.goUrl(urls);
      } else {
        window.open(item.url);
      }
    }
  },
  // 周岁年龄
  jsGetAge(strBirthday) {
    var returnAge;
    var strBirthdayArr = strBirthday.split("-");
    var birthYear = strBirthdayArr[0];
    var birthMonth = strBirthdayArr[1];
    var birthDay = strBirthdayArr[2];

    let d = new Date();
    var nowYear = d.getFullYear();
    var nowMonth = d.getMonth() + 1;
    var nowDay = d.getDate();

    if (nowYear == birthYear) {
      returnAge = 0; //同年 则为0岁
    } else {
      var ageDiff = nowYear - birthYear; //年之差
      if (ageDiff > 0) {
        if (nowMonth == birthMonth) {
          var dayDiff = nowDay - birthDay; //日之差
          if (dayDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        } else {
          var monthDiff = nowMonth - birthMonth; //月之差
          if (monthDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        }
      } else {
        returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
      }
    }
    return returnAge; //返回周岁年龄
  },
  //把富文本中的图片隐藏
  formatRichText(html) {
    let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
      match = match.replace(/style="[^"]+"/gi, "");
      match = match.replace(/<img/, `<img style="display: none;"`);
      return match;
    });
    return newContent;
  },
  //秒转分
  formatSeconds(value) {
    let theTime = parseInt(value); // 秒
    let middle = 0; // 分
    if (theTime > 60) {
      middle = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
    }
    return `${middle > 9 ? middle : "0" + middle} : ${
      theTime > 9 ? theTime : "0" + theTime
    }`;
  },
};
