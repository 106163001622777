export default [
  // 消息
  {
    name: "news",
    path: "/news",
    meta: {
      title: "消息",
      requireAuth: true,
    },
    component: () => import("@view/news/index.vue"),
  },
 
  
];
