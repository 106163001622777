import http from "@/utils/api/http";

export default {
  // 注册
  registered(data) {
    return http.post("/dkt/login/registered", data);
  },
  register_user(data) {
    return http.post("/dkt/login/register_user", data);
  },
  verify_version(data) {
    return http.post("/api/login/verify_version", data);
  },

  //省市区
  get_province(data) {
    return http.post("/dkt/login/get_province", data);
  },

  get_city(data) {
    return http.post("/dkt/login/get_city", data);
  },
  get_area(data) {
    return http.post("/dkt/login/get_area", data);
  },

  //注销用户
  remobeUser(data) {
    return http.post("/dkt/index/del_user", data);
  },
  del_user_info(data) {
    return http.post("/dkt/index/del_user_info", data);
  },

  //登录
  login(data) {
    return http.post("/dkt/login/login", data);
  },
  //发送短信
  massCode(data) {
    return http.post("/dkt/sms/massCode", data);
  },
  //忘记密码确认验证码
  forget_pas_code(data) {
    return http.post("/dkt/login/forget_pas_code", data);
  },
  //忘记密码—修改密码
  forget_pas(data) {
    return http.post("/dkt/login/forget_pas", data);
  },
  guide(data) {
    return http.post("/dkt/other/guide", data);
  },
  // 用户协议/隐私协议
  getAgreement(data) {
    return http.post("/dkt/login/agreement", data);
  },

  //大众药评用户协议/隐私协议
  getAgreementMax(data) {
    return http.post("/api/login/agreement", data);
  },

  //APP微信登录
  getWxUser(data) {
    return http.post("/dkt/login/getWxUser", data);
  },
  //绑定手机号
  getBindPhone(data) {
    return http.post("/dkt/login/bindPhone", data);
  },
  //微信小程序登录1
  getwxMplogin(data) {
    return http.post("/dkt/login/wxMplogin", data);
  },
  //获取微信手机号2
  getdkt_wx_get_phone(data) {
    return http.post("/dkt/login/dkt_wx_get_phone", data);
  },
  //注册用户数据--微信用户授权后合并数据3
  getwx_register(data) {
    return http.post("/dkt/login/wx_register", data);
  },
  // ios登录
  iosLogin(data) {
    return http.post("/dkt/login/iosLogin", data);
  },
  //环信客服获取
  getHxIm(data) {
    return http.post("/dkt/login/hxIm", data);
  },

  // seo主要内容
  seoDescription(data) {
    return http.post("/dkt/login/agreement", data);
  },

  // 授权登录
  authLogin(data) {
    return http.post("/dkt/auth/login", data);
  },

  // 根据drsay_key获取上医说医生
  drsayUser(data) {
    return http.post("/dkt/auth/drsay_user", data);
  },

  // 优医迈注册科室
  authGetDepartment(data) {
    return http.post("/dkt/auth/get_department", data);
  },

  // 优医迈注册地区
  authArea(data) {
    return http.post("/dkt/auth/area", data);
  },

  // 优医迈注册用户
  authAddUser(data) {
    return http.post("/dkt/auth/add_user", data);
  },

  // 客服
  getChatList(data) {
    return http.post("/dkt/chat/get_chat_list", data);
  },

  // 客服评分
  setChatScore(data) {
    return http.post("/dkt/chat/set_chat_score", data);
  },

  // 验证客服24h
  verifyIsScore(data) {
    return http.post("dkt/chat/verify_is_score", data);
  },

};
