export default [
  // 搜索
  {
    name: "searchFor",
    path: "/searchFor",
    meta: {
      title: "搜索",
    },
    component: () => import("@view/searchFor/index.vue"),
  },
];
