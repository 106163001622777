"use strict";
import axios from "axios";
// 引入查询字符串(用于解析和格式化URL查询字符串的实用工具)
import qs from "qs";
import store from "@/store";
import { Message } from "element-ui";
// 基准地址
const baseURL = "https://api.idr.cn";
const timeout = 600000;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 判断是不是在登录状态
    let token = localStorage.getItem("token") || "";
    if (token) {
      config.headers.common["token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 响应处理
    if (response.data.code != 200 && response.data.code != 400) {
      if (response.data.code == -1 || response.data.code == -40000) {
        store.dispatch("userManagement/logout", true);
      }
    }
    return response;
  },
  (error) => {
    return Promise.resolve(error.response);
  }
);

//响应拦截器（最外层）
function checkStatus(response) {
  if (response) {
    if (
      response.status === 200 ||
      response.status === 304 ||
      response.status === 400
    ) {
      return response;
    }
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }
  // 异常状态下，把错误信息返回去
  return {
    status: -404,
    msg: "网络异常",
  };
}

//响应拦截器（内层）
// 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
function checkCode(res) {
  if (res.status && res.status === -404) {
    Message({
      message: res.status + (res.msg || "服务器请求错误"),
      type: "error",
    });
  }
  // if (res.data && (!res.data.success)) {
  //     Message({message:(res.data.status || 500 + ( res.data.error_msg|| '服务器请求错误') ),type: 'error'})
  // }
  return res.data;
}

export default {
  post(url, data, type) {
    let headers;
    if (type) {
      headers = {
        "Content-Type": "multipart/form-data",
      };
    } else {
      headers = {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      };
      data = qs.stringify(data);
    }
    return axios({
      method: "post",
      baseURL,
      url,
      data,
      timeout,
      headers,
    })
      .then((response) => {
        return checkStatus(response);
      })
      .then((res) => {
        return checkCode(res);
      });
  },
  get(url, params) {
    return axios({
      method: "get",
      baseURL,
      url,
      params, // get 请求时带的参数
      timeout,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        return checkStatus(response);
      })
      .then((res) => {
        return checkCode(res);
      });
  },
  baseURL,
};
