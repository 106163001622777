// 时间格式处理
const timeFormat = (value, format) => {
  if (!value) return "";
  let date;
  if (String(value).indexOf("-") != -1) {
    date = new Date(value);
  } else {
    date = new Date(value * 1000);
  }
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  let h = date.getHours();
  let min = date.getMinutes();
  let s = date.getSeconds();
  let result = "";
  if (format == undefined) {
    result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d} ${
      h < 10 ? "0" + h : h
    }:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`;
  }
  switch (format) {
    case "yyyy-mm-dd":
      result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
      break;

    //精确到分
    case "yyyy-mm-dd-hh:mm":
      result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d} ${
        h < 10 ? "0" + h : h
      }:${min < 10 ? "0" + min : min}`;
      break;
    //精确到分
    case "yyyy-mm":
      result = `${y}-${m < 10 ? "0" + m : m}`;
      break;
    case "mm-dd":
      result = ` ${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
      break;
    case "hh:mm":
      result = ` ${h < 10 ? "0" + h : h}:${min < 10 ? "0" + min : min}`;
      break;
    case "yyyy":
      result = `${y}`;
      break;
    case "d":
      result = `${d < 10 ? "0" + d : d}`;
      break;
    case "y":
      result = `${y}`;
      break;
    case "m":
      result = `${m < 10 ? "0" + m : m}`;
      break;
    case "年月日":
      result = `${y}年${m < 10 ? "0" + m : m}月${d < 10 ? "0" + d : d}日`;
      break;
  }

  return result;
};
const formaTime = (time) => {
  if (!time) return "--";
  time = time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
};

// 浏览量格式处理
let pageviews = (val) => {
  let size = String(val).length;
  if (size >= 5) {
    return (val / 10000).toFixed(1) + "万";
  } else {
    return val;
  }
};

// 数量格式处理
const amount = (val) => {
  if (!val) {
    return 0;
  }
  let size = String(val).length;
  if (size >= 5) {
    return (val / 10000).toFixed(1) + "万";
  } else {
    return val;
  }
};

// 手机号格式处理
const hidePhone = (val) => {
  if (val) return val.slice(0, 3) + "****" + val.slice(7, 11);
  else return "";
};

// @格式处理
const division = (val) => {
  if (val) return val.replace(/@/g, " ");
  else return "";
};

export { timeFormat, pageviews, formaTime, amount, hidePhone, division };
