<template>
  <main>
    <!-- 头部-->
    <header v-if="showCommon && $route.path != '/settingTwo'">
      <!-- nav -->
      <section class="header-box">
        <img
          class="logo"
          @click="$router.push({ path: '/' })"
          src="@image/logo.png"
        />
        <nav>
          <template v-for="(navItem, navIndex) in navList">
            <span
              ref="navIndex"
              :class="{ active: navIndex == isNavIndex }"
              :key="navIndex"
              @click="jumpRouter(navIndex, navItem)"
              >{{ navItem.label }}</span
            >
          </template>
        </nav>

        <!-- 搜索框-->
        <el-input
          autocomplete="off"
          class="searchInput"
          @click.native="xin.goUrl('/searchFor')"
          readonly
          v-model="input"
          placeholder="搜索内容"
          suffix-icon="el-icon-search"
          @keyup.enter.native="search()"
        ></el-input>

        <!-- 信息提示按钮 -->
        <div class="personal-img" @click="xin.goUrl('/notification')">
          <div v-if="userParam.notice" class="icon-personal">
            {{ userParam.notice }}
          </div>
          <img class="news" src="@image/msg.svg" />
        </div>
        <!-- 登录状态 -->
        <div class="portrait">
          <!-- 已登陆 -->
          <el-dropdown v-if="userInfo.id">
            <img
              @click="$router.push({ path: '/personalCenter' })"
              class="doctor"
              :src="
                userInfo.avatar
                  ? userInfo.avatar
                  : require('@image/default.png')
              "
            />
            <el-dropdown-menu slot="dropdown" class="chengShen">
              <el-dropdown-item @click.native="goMenu('/userCenter')"
                >我的收藏</el-dropdown-item
              >
              <el-dropdown-item @click.native="goMenu('/userCenter?index=3')"
                >历史</el-dropdown-item
              >
              <el-dropdown-item @click.native="goMenu('/userCenter?index=1')"
                >我的评论</el-dropdown-item
              >
              <el-dropdown-item @click.native="goMenu('/userCenter?index=2')"
                >我的点赞</el-dropdown-item
              >
              <el-dropdown-item @click.native="goMenu('/homepage')" divided
                >个人主页</el-dropdown-item
              >
              <el-dropdown-item @click.native="goMenu('/setting')"
                >设置</el-dropdown-item
              >
              <el-dropdown-item @click.native="removeInfo"
                >注销</el-dropdown-item
              >
              <el-dropdown-item @click.native="exit" divided
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 未登录 -->
          <template v-else>
            <span @click="$router.push({ path: '/register' })">注册</span>
            <span class="line">|</span>
            <span @click="showLogin = true">登录</span>
          </template>
        </div>
      </section>

      <div class="suspension">
        <div @click="contactCustomerService">
          <img class="clothing" src="@image/kefu.png" />
          <span>联系客服</span>
        </div>
        <div>
          <el-popover placement="right" width="150" trigger="hover">
            <img class="img-rem" src="@image/code.png" />
            <div slot="reference">
              <img class="clothing" src="@image/qr.png" />
              <span>APP下载</span>
            </div>
          </el-popover>
        </div>
        <div @click="isOpinion = true">
          <img class="clothing" src="@image/yijian.png" />
          <span>意见反馈</span>
        </div>
        <div class="box-fuan">
          <el-popover placement="right" width="150" trigger="hover">
            <img class="img-rem" src="@image/official_account.jpg" alt="" />
            <div style="text-align: center">扫码关注公众号</div>
            <div slot="reference">
              <img class="clothing" src="@image/qr.png" />
              <span>关注公众号</span>
            </div>
          </el-popover>
        </div>
      </div>
    </header>

    <!-- 插槽 -->
    <slot name="default"></slot>

    <!-- 底部 -->
    <footer>
      <div
        v-if="showCommon && $route.path != '/settingTwo'"
        class="header-main"
      >
        <section class="bottom-box">
          <div class="logo">
            <img class="icon" src="@image/logo2.png" />
            <span>网医</span>
          </div>
          <div class="contact">
            <span class="title">联系我们</span>
            <span>邮箱：sales-group@gooddr.com</span>
            <span>传真：(+86)010-64162664</span>
            <span>电话：(+86)010-64162328</span>
          </div>
          <div class="address">
            <div
              class="contact2"
              v-for="(item, index) in addressList"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <span class="english">{{ item.eng }}</span>
            </div>
          </div>
        </section>
      </div>
      <div class="statement" v-if="$route.path != '/settingTwo'">
        <span class="one">
          特别声明：本站内容仅供参考，不作为诊断及医疗依据
          互联网药品信息服务资格证书：（京）-经营性-2020-0006
        </span>
        <a
          style="text-decoration: none; color: #333; cursor: pointer"
          target="_blank"
          href="https://beian.miit.gov.cn"
          >Copyright ©2014-{{ currnetYear }} 健康通（北京）网络科技有限公司
          保留所有权 | 京ICP备13039326号-12
        </a>
      </div>
    </footer>

    <!-- 对话框 -->

    <!-- 登录对话框 -->
    <el-dialog
      width="600px"
      custom-class="login"
      :visible.sync="showLogin"
      :before-close="closeCode"
    >
      <div
        v-if="loginType != 'QR'"
        class="tabType"
        @click="
          loginType = 'QR';
          postLoginBin_wx();
        "
      >
        <el-popover
          placement="top-start"
          title="安全登录"
          width="200"
          trigger="hover"
          content="使用微信扫码登录。"
        >
          <img slot="reference" src="@image/tabType.png" alt="切换登陆方式" />
        </el-popover>
      </div>
      <div class="section">
        <el-tabs v-model="loginType" stretch>
          <el-tab-pane label="账号登录" name="account">
            <div class="form">
              <el-input
                autocomplete="off"
                class="account"
                v-model.trim="accountForm.username"
                placeholder="请输入手机号/身份证号"
                prefix-icon="el-icon-user"
              >
              </el-input>
              <el-input
                autocomplete="off"
                class="password"
                v-model.trim="accountForm.password"
                placeholder="请输入密码"
                show-password
                prefix-icon="el-icon-lock"
              >
              </el-input>
              <div class="remark">
                <span class="forgotPassword">
                  <span
                    style="cursor: pointer"
                    @click="
                      $router.push({ path: '/forgotPassword' }),
                        (showLogin = false)
                    "
                    >忘记密码</span
                  >
                </span>
                <el-checkbox v-model="rememberPassword">记住密码</el-checkbox>
              </div>
              <el-button round @click="account">登录</el-button>
              <div>
                <el-checkbox class="readAndAgree" v-model="readAndAgree"
                  >阅读并同意
                </el-checkbox>
                <span
                  class="lan-a"
                  @click="
                    xin.goUrl('/setting?index=2');
                    showLogin = false;
                  "
                  >《用户协议》</span
                >
                <span
                  class="lan-a"
                  @click="
                    xin.goUrl('/setting?index=3');
                    showLogin = false;
                  "
                  >《隐私声明》</span
                >
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="短信登录" name="code">
            <div class="form">
              <el-input
                autocomplete="off"
                class="account"
                v-model="codeForm.mobile"
                placeholder="请输入手机号"
                prefix-icon="el-icon-user"
              >
              </el-input>
              <el-input
                autocomplete="off"
                class="code"
                v-model="codeForm.code"
                placeholder="请输入验证码"
                prefix-icon="el-icon-message"
              >
                <template slot="append">
                  <div v-if="isCode">{{ codeNum }}秒后重试</div>
                  <div v-else @click="massCode">获取验证码</div>
                </template>
              </el-input>
              <div class="remark">
                <span class="forgotPassword">
                  <span
                    style="cursor: pointer"
                    @click="
                      $router.push({ path: '/forgotPassword' }),
                        (showLogin = false)
                    "
                    >忘记密码</span
                  >
                </span>
                <!-- <el-checkbox v-model="rememberPassword">记住密码</el-checkbox> -->
              </div>
              <el-button round @click="login('codeForm')">登录</el-button>
              <div>
                <el-checkbox class="readAndAgree" v-model="readAndAgree"
                  >阅读并同意
                </el-checkbox>
                <span
                  class="lan-a"
                  @click="
                    xin.goUrl('/setting?index=2');
                    showLogin = false;
                  "
                  >《用户协议》</span
                >
                <span
                  class="lan-a"
                  @click="
                    xin.goUrl('/setting?index=3');
                    showLogin = false;
                  "
                  >《隐私声明》</span
                >
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div v-if="loginType == 'QR'" class="QR-Login">
          <wxlogin
            :appid="'wx6b2893abfdaf4bd7'"
            :scope="'snsapi_login'"
            :redirect_uri="encodeURI('http://www.idr.cn//#/index')"
          ></wxlogin>
        </div>
        <div class="noAccount">
          <span>还没有账号？</span>
          <span class="register" @click="goRegistered">立即注册</span>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="560px" custom-class="login" :visible.sync="showModal">
    </el-dialog>

    <el-dialog
      width="560px"
      class="cancellation"
      custom-class="login"
      :visible.sync="showModalCancellation"
    >
      <div class="form" style="height: 336px">
        <div class="cancellationTitle">
          <div>注销此账号</div>
          <div class="cancellationLine"></div>
        </div>

        <el-input
          autocomplete="off"
          class="account"
          v-model="cancelForm.phone"
          placeholder="请输入手机号"
          prefix-icon="el-icon-user"
        >
        </el-input>
        <el-input
          autocomplete="off"
          class="code"
          v-model="cancelForm.code"
          placeholder="请输入验证码"
          prefix-icon="el-icon-message"
        >
          <template slot="append">
            <div v-if="isCode">{{ cancelNum }}秒后重试</div>
            <div v-else @click="masscancelCode">获取验证码</div>
          </template>
        </el-input>

        <el-button
          class="cancellationButton"
          round
          @click="cancelFun('cancelForm')"
          >注销</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      width="560px"
      class="cancellation"
      custom-class="login"
      :visible.sync="showModalCancellationSuccess"
    >
      <div class="form" style="height: 336px">
        <img
          src="../../assets/image/chengSuccess.png"
          class="chengSuccessImg"
        />
        <div class="chengSuccessDiv">注销成功!</div>
      </div>
    </el-dialog>

    <!-- 意见反馈对话框 -->
    <el-dialog
      width="800px"
      custom-class="opinion"
      :before-close="opinionClose"
      :visible.sync="isOpinion"
    >
      <div class="title">意见反馈</div>
      <div class="input-name">问题详情描述<span>（必填）</span></div>
      <textarea
        v-model="opinion.content"
        placeholder="请输入您的详细问题，我们将为您尽快解决"
      ></textarea>
      <div class="images-box">
        <div class="imgs" v-for="(item, i) in opinion.images" :key="i">
          <img :src="item" alt="" />
          <div class="rowp">
            <span @click="removeImg(i)" class="el-icon-delete"></span>
            <span
              @click="
                isImg = true;
                imgSrc = item;
              "
              class="el-icon-zoom-in"
            ></span>
          </div>
        </div>
        <div v-if="opinion.images.length < 9" class="add-img">
          <i class="el-icon-plus"> </i>
          <input type="file" @change="uploadImg" multiple ref="imgTop" />
          配图（选填）
        </div>
      </div>
      <div class="input-name">手机号<span>（必填）</span></div>
      <div class="input-phone">
        <input v-model="opinion.phone" type="text" />
      </div>
      <div class="btns">
        <el-button :disabled="isBtn" @click="isOpinion = false">取消</el-button>
        <el-button :loading="isBtn" @click="postPersonalFeedback" type="primary"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 加载失败对话框 -->
    <el-dialog custom-class="login" :visible.sync="isImg">
      <el-image :src="imgSrc"></el-image>
    </el-dialog>

    <!--  举报对话框 -->
    <el-dialog custom-class="login" :visible.sync="isJubao" class="chengJuOne">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>举报</span>
          <div>请选择举报理由</div>
        </div>
        <el-radio-group v-model="radio" class="radio">
          <div>
            <el-radio label="垃圾广告信息">垃圾广告信息</el-radio>
          </div>
          <div>
            <el-radio label="不实信息">不实信息</el-radio>
          </div>
          <div>
            <el-radio label="辱骂、人身攻击等不友善行为"
              >辱骂、人身攻击等不友善行为</el-radio
            >
          </div>
          <div>
            <el-radio label="有害信息">有害信息</el-radio>
          </div>
          <div>
            <el-radio label="涉嫌侵权">涉嫌侵权</el-radio>
          </div>
          <div>
            <el-radio label="诱导赞同、关注等行为"
              >诱导赞同、关注等行为</el-radio
            >
          </div>
        </el-radio-group>
        <div style="text-align: center">
          <el-button type="primary" @click="reportSubmit">下一步</el-button>
        </div>
        <div style="text-align: center; margin-top: 10px">
          <el-checkbox v-model="checked">我已阅读并同意</el-checkbox
          ><span
            style="cursor: pointer"
            @click="
              xin.goUrl('/setting?index=5');
              isJubao = false;
            "
            >《举报须知》</span
          >
        </div>
      </el-card>
    </el-dialog>

    <div
      v-show="isJubaoTWo"
      class="isJubao"
      style="width: 635rpx; height: 446px"
    >
      <el-dialog custom-class="login" :visible.sync="isJubaoTWo" width="635rpx">
        <div class="dialogDiv">
          <div class="title">举报</div>
          <div class="messageDiv">
            <img src="@image/editImg.png" />
            <div class="messageDivContentDiv">
              您的举报将在12小时内受理,若举报成功会第一时间告知处理结果,请尽量提交完整的举报描述及材料.
            </div>
          </div>
          <div class="reasonDiv">
            <div class="titleCheng">
              <div class="titleInput" style="display: inline-block">
                举报理由
              </div>
              <!-- <div class="titleInput titleInputMagin" style="display: inline-block;">涉嫌欺诈</div> -->
              <input
                type="text"
                v-model="reasonInput"
                placeholder="涉嫌欺诈"
                class="titleInputMagin"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="problemDiv">
            举报描述<span>(选填)</span>
            <el-input
              autocomplete="off"
              resize="none"
              v-model="reasonText"
              class="problemDivInput"
              border
              :rows="1"
              type="textarea"
              placeholder="详细描述举报理由"
            ></el-input>
          </div>

          <div style="margin-top: 20px">
            <!-- https://api.idr.cn/dkt/discover/image_upload -->
            <el-upload
              :limit="4"
              ref="upload1"
              multiple
              :headers="{ token: token }"
              :action="$http.baseURL + '/dkt/discover/image_upload'"
              name="img"
              :on-success="succseeFile"
              list-type="picture-card"
              :before-upload="progressImg"
              :file-list="filelists1"
              class="fabu"
              width="64"
              height="64"
            >
              <!-- <i slot="default" class="el-icon-plus"></i> -->
              <div slot="default" style="position: relative">
                <img src="@image/camera.png" class="uploadImgCheng" />
                <div
                  slot="tip"
                  style="
                    display: inline;
                    position: absolute;
                    top: 20%;
                    left: 32%;
                    color: #bcbcbc;
                  "
                >
                  {{ filelists1.length }}/4
                </div>
              </div>

              <div
                slot="file"
                slot-scope="{ file }"
                style="width: 64px; height: 64px"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt="file"
                  style="width: 100%; height: 100%"
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreviewXin(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>

                  <span
                    v-if="!disabledXin"
                    class="el-upload-list__item-delete"
                    @click="handleRemoveXin('upload1', file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </div>

          <el-dialog :visible.sync="dialogVisibleXin" class="bigXinView">
            <img width="100%" :src="dialogImageUrlXin" alt="" />
          </el-dialog>

          <!-- <div class="uploadButtonDiv"> -->
          <div class="buttonDiv">
            <!-- <button @click="cancelIsJubao">取消</button> -->
            <button @click="submitIsJubao">提交</button>
          </div>
          <!-- </div> -->
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibleMax" class="chengDialog">
        <img width="100%" :src="imgView" alt="" />
      </el-dialog>

      <el-dialog
        title="提示"
        class="chengshen"
        :append-to-body="true"
        :visible.sync="dialogVisibleTwo"
        width="560px"
        :before-close="handleClose"
      >
        <span>学习请先进行身份认证</span>
        <div class="popupsButton">
          <span
            @click="dialogVisibleTwo = !dialogVisibleTwo"
            class="popupsJudgment"
            >取消</span
          >
          <span @click="go" class="popupsJudgment">认证</span>
        </div>
      </el-dialog>
    </div>

    <template v-if="isShowKefu">
      <kefu :isShowKefu="isShowKefu" ref="kefu"></kefu>
    </template>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import store from "@/store";
import Kefu from "@components/common/kefu";
import wxlogin from "vue-wxlogin";

export default {
  components: {
    Kefu,
    wxlogin,
  },

  data() {
    return {
      currnetYear: new Date().getFullYear(),
      cancelForm: {
        phone: "",
        code: "",
      },
      dialogVisibleTwo: false,
      showModalCancellationSuccess: false,
      showModalCancellation: false,
      userAgreement: "",
      privacyStatement: "",
      token: localStorage.getItem("token") || "",
      userId: "",
      qrCodeSrc: "", //二维码地址
      isQrCode: false, //二维码是否显示
      codeKey: "", //二维码key
      cunrli: -1, //二维码定时器
      input: null,
      radio: "垃圾广告信息",
      checked: false,
      isOpinion: false,
      showLogin: false,
      isJubao: false,
      isJubaoTWo: false,
      reporId: "",
      loginType: "account",
      rememberPassword: false,
      readAndAgree: false,
      navList: [
        {
          label: "首页",
          path: "/index",
        },
        {
          label: "发现",
          path: "/found",
        },
        {
          label: "创作",
          path: "/creation",
        },
        {
          label: "调研",
          path: "/research",
        },
        {
          label: "关于我们",
          path: "",
        },
      ],
      dialogImageUrlXin: "",
      dialogVisibleXin: false,
      disabledXin: false,
      reasonText: "",
      reasonInput: "",
      dialogVisibleMax: false,
      imgView: "",
      filelists1: [],
      isNavIndex: 0,
      accountForm: {
        username: null,
        password: null,
      },
      codeForm: {
        mobile: "",
        code: "",
      },
      isCode: false,
      isImg: false,
      isBtn: false,
      imgSrc: "",
      opinion: {
        content: "", //内容
        phone: "", //手机
        images: [], //图片
      },
      addressList: [
        {
          name: "地址：北京市东城区广渠门内大街雍贵中心B座302",
          eng: "302, Block B, Yonggui Center, Guangqumen Inner Street, Dongcheng District, Beijing",
        },
        {
          name: "地址：上海市浦东新区平家桥路100弄晶耀前滩T7-905室",
          eng: "Room 905, Building T7, Crystal Plaza, Lane 100, Pingjiaqiao Road, Pudong New District，Shanghai",
        },
      ],
      isShowKefu: false,
      kefuId: "jkt",
      type: "",
    };
  },

  watch: {
    loginType(val) {
      if (val != "QR") {
        this.closeCode();
      }
    },
    $route: {
      handler(val) {
        let routerRame = val.name;
        if (routerRame.startsWith("index")) {
          this.isNavIndex = 0;
        } else if (routerRame.startsWith("found")) {
          this.isNavIndex = 1;
        } else if (routerRame.startsWith("creation")) {
          this.isNavIndex = 2;
        } else if (routerRame.startsWith("research")) {
          this.isNavIndex = 3;
        } else {
          this.isNavIndex = -1;
        }
        // 发布创造页面对应为“创作”。
        if (routerRame.startsWith("found-release-index")) {
          this.isNavIndex = 2;
        }
      },
      deep: true,
    },
  },

  created() {
    this.verify_version();
    //获取地址栏链接带？以及后面的字符串
    var url = window.location.search;
    //如果字符串里面存在?
    if (url.indexOf("?") != -1) {
      //从url的索引1开始提取字符串
      var str = url.substring(1);
      //如果存在&符号，则再以&符号进行分割
      var arr = str.split("&");
      //定义一个空对象
      var obj = {};
      //遍历数组
      for (var i = 0; i < arr.length; i++) {
        // obj对象的属性名 = 属性值，unescape为解码字符串
        obj[arr[i].split("=")[0]] = unescape(arr[i].split("=")[1]);
      }

      if (typeof obj.code !== "undefined") {
        this.wxDoLogin(obj.code);
      }
    }
    this.bus.$on("method", this.tabShowLogin);

    // 举报文章id和类型
    this.bus.$on("report", (discover_id, type, userId) => {
      this.isJubao = true;
      this.reporId = discover_id;
      this.type = type;
      this.userId = userId;
    });

    if (localStorage.getItem("token")) {
      this.getUserInfo();
      this.getPersonal();
    }
    let loginUser = localStorage.getItem("loginUser")
      ? JSON.parse(localStorage.getItem("loginUser"))
      : "";
    if (loginUser) {
      this.accountForm.username = loginUser.username;
      this.accountForm.password = loginUser.password;
      this.rememberPassword = true;
    }

    this.getSeoDescription();
  },

  computed: {
    ...mapState("userManagement", ["userInfo", "userParam"]),
    ...mapState("appManagement", ["showModal"]),
    // 判断头部和底部section的显示与隐藏
    showCommon() {
      const noPage = [
        "/register",
        "/forgotPassword",
        "/oauth",
        "/oauthRegister",
      ];
      if (noPage.includes(this.$route.path)) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("userManagement", [
      "saveLogin",
      "logout",
      "getUserInfo",
      "getPersonal",
    ]),
    go() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/authentication",
      });
      this.dialogVisibleTwo = false;
    },
    handleClose(done) {
      done();
    },

    // 注销
    async cancelFun() {
      let res = await this.$loginApi.del_user_info({
        phone: this.cancelForm.phone,
        code: this.cancelForm.code,
      });
      if (res.code == 200) {
        this.showModalCancellation = false;
        this.showModalCancellationSuccess = true;
        this.logout();
        this.showModalCancellation = false;
        this.cancelForm.phone = "";
        this.cancelForm.code = "";
        // store.dispatch("userManagement/logout");
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
      // })
    },

    //注销发短信
    async masscancelCode() {
      let { phone } = this.cancelForm;
      if (phone) {
        let res = await this.$loginApi.massCode({
          phone: phone,
          type: 2,
        });
        if (res.code == 200) {
          this.$message({
            message: "短信已发送！",
            type: "success",
          });
          this.cancelNum = 60;
          this.isCode = true;
          let inter = setInterval(() => {
            this.cancelNum--;
            if (this.cancelNum <= 0) {
              this.isCode = false;
              clearTimeout(inter);
            }
          }, 1000);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else
        this.$message({
          message: "手机号不能为空！",
          type: "error",
        });
    },

    async verify_version() {
      let res = await this.$loginApi.verify_version({});
      if (res.code == 200) {
        this.userAgreement = new Date(
          parseInt(res.data.user_information.update_time) * 1000
        )
          .toLocaleString()
          .replace(/:\d{1,2}$/, " ");
        this.privacyStatement = new Date(
          parseInt(res.data.privacy_information.update_time) * 1000
        )
          .toLocaleString()
          .replace(/:\d{1,2}$/, " ");
      } else {
        this.$message.error(res.message);
      }
    },

    // seo关键字
    async getSeoDescription() {
      const res = await this.$loginApi.seoDescription();
      let seoDescription = res.data.seo
        .replace(/<[^>]+>/g, "")
        .replace(/&nbsp;/gi, "");
      document
        .getElementById("description")
        .setAttribute("content", seoDescription);
    },

    // nav的跳转
    jumpRouter(index, item) {
      if (item.label == "关于我们") {
        window.open(
          "https://dingkaotong.oss-cn-beijing.aliyuncs.com/download/about.pdf"
        );
        return;
      }

      this.$router.push({
        path: item.path,
      });
      let token = localStorage.getItem("token");
      if (index == 3 && token) {
        if (!this.userInfo.company) {
          this.dialogVisibleTwo = true;
        }
      }
    },

    // 注销用户
    removeInfo() {
      this.showModalCancellation = true;
    },

    // 客服
    async contactCustomerService() {
      if (!localStorage.getItem("token")) {
        return this.$message.error("请登录");
      }
      let token = localStorage.getItem("token");
      if (token) {
        this.isShowKefu = true;
        // 新客服列表
        let res = await this.$loginApi.getChatList({
          sysid: "5",
          user_id: this.userInfo.id,
        });
        if (res.code == 200) {
          this.kefuId = res.data.account;
          this.$refs.kefu.getChatData(this.kefuId);
          this.$refs.kefu.kefuName = res.data.name;
          this.$refs.kefu.chat_id = res.data.sysid;
          this.$refs.kefu.isShowKefu = true;
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.$message("请先登录");
        this.showLogin = true;
      }

      //跳转到客服页面传type参数
    },

    // dropdown的跳转
    goMenu(url) {
      if (url == "exit") {
        this.exit();
        return false;
      }
      this.xin.goUrl(url);
    },
    //去注册
    goRegistered() {
      this.showLogin = false;
      this.closeCode();
      this.xin.goUrl("/register");
    },
    //关闭二维码
    closeCode(dom) {
      this.qrCodeSrc = "";
      this.loginType = "account";
      clearInterval(this.cunrli);
      if (dom) dom();
    },
    //获取二维码
    async postLoginBin_wx() {
      this.qrCodeSrc = "";
      this.showLogin = true;
      // return;
      // let { code, data } = await this.$userApi.postGenerateTwoQrcode({ login: 1 });
      // if (code == 200) {
      //     this.qrCodeSrc = data.url;
      //     this.codeKey = data.key;
      //     this.qrPolling();
      // }
    },
    //二维码轮询
    qrPolling() {
      this.cunrli = setInterval(async () => {
        let { code, data, message } = await this.$userApi.getLoginQuery({
          key: this.codeKey,
        });
        if (code == 200) {
          localStorage.setItem("token", data.token);
          await this.getUserInfo();
          this.getPersonal();
          this.$message({
            message: "登录成功",
            type: "success",
          });
          this.closeCode();
          this.showLogin = false;
        } else if (code != 400) {
          this.closeCode();
          this.showLogin = false;
        } else {
          this.$message.error(message);
        }
      }, 3000);
    },
    //微信登录回调
    async wxDoLogin(param) {
      let { code, data, message } = await this.$userApi.wxDoLogin({
        code: param,
      });

      if (code == 200) {
        localStorage.setItem("token", data);
        await this.getUserInfo();
        this.getPersonal();
        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.showLogin = false;
        history.replaceState(null, null, "/");
        this.registerWebIm();
      } else {
        this.$message.error(message);
      }
    },
    //议价反馈
    async postPersonalFeedback() {
      let form = Object.assign({}, this.opinion);
      if (!form.content) {
        this.xin.message("请填写详细内容!", "err");
      } else if (!form.phone) {
        this.xin.message("请输入手机号!", "err");
      } else if (form.phone.length != 11) {
        this.xin.message("请输入正确的手机号!", "err");
      } else {
        form.images = JSON.stringify(form.images);
        this.isBtn = true;
        let { code, message } = await this.$userApi.postPersonalFeedback(form);
        this.isBtn = false;
        if (code == 200) {
          this.xin.message("提交成功!");
          this.isOpinion = false;
          this.opinion = this.$options.data().opinion;
        } else {
          this.$message.error(message);
        }
      }
    },
    //关闭意见反馈回调
    opinionClose(done) {
      if (this.isBtn) return;
      this.opinion = this.$options.data().opinion;
      done();
    },
    //删除图片
    removeImg(i) {
      this.opinion.images.splice(i, 1);
    },
    //图片上传  8  4
    async uploadImg() {
      let files = [...this.$refs.imgTop.files];
      let maxNum = 9 - this.opinion.images.length;
      // return
      if (!files.length || !maxNum) {
        return;
      } else if (files.length > maxNum) {
        files.splice(maxNum, files.length);
      }
      // return;
      let ajaxNum = 0;
      this.$loading.show();
      files.forEach(async (v, i) => {
        let formData = new FormData();
        formData.append("files", v);
        let { code, data, message } = await this.$userApi.postFilesUpload(
          formData
        );
        ajaxNum++;
        if (code == 200) {
          this.opinion.images.push(data);
        } else {
          this.$message.error(message);
        }
        if (ajaxNum == files.length) {
          this.$loading.hide();
        }
      });
    },
    //发短信
    async massCode() {
      let { mobile } = this.codeForm;
      if (mobile) {
        let res = await this.$loginApi.massCode({
          phone: mobile,
          type: 2,
        });
        if (res.code == 200) {
          this.$message({
            message: "短信已发送！",
            type: "success",
          });
          this.codeNum = 60;
          this.isCode = true;
          let inter = setInterval(() => {
            this.codeNum--;
            if (this.codeNum <= 0) {
              this.isCode = false;
              clearTimeout(inter);
            }
          }, 1000);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else
        this.$message({
          message: "手机好不能为空！",
          type: "error",
        });
    },
    //短信登录
    async login() {
      event.preventDefault();
      let { mobile, code } = this.codeForm;
      if (!mobile) {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
        return;
      } else if (!code) {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
        return;
      } else if (!this.readAndAgree) {
        this.$message.error("请阅读勾选用户协议及隐私政策");
        return;
      }
      let res = await this.$loginApi.login({
        type: 3,
        phone: mobile,
        code,
      });
      if (res.code == 200) {
        localStorage.setItem("token", res.data);
        await this.getUserInfo();
        this.getPersonal();
        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.showLogin = false;
        if (this.$router.path != "/index") {
          this.xin.goUrl("/");
        }
        this.registerWebIm();
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    // 退出
    exit() {
      this.logout();
      // localStorage.removeItem("token");
      sessionStorage.removeItem("iszanOne");
      sessionStorage.removeItem("iszanTwo");
      sessionStorage.removeItem("iszanThree");
      sessionStorage.removeItem("iszanFour");

      this.$message.success("已退出登录");
    },
    // 密码登录
    async account() {
      let accountForm = this.accountForm;
      if (!accountForm.username) {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
        return;
      } else if (!accountForm.password) {
        this.$message({
          message: "请输入密码",
          type: "error",
        });
        return;
      } else if (!this.readAndAgree) {
        this.$message.error("请阅读勾选用户协议及隐私政策");
        return;
      }
      this.$loading.show();
      if (await this.saveLogin(accountForm)) {
        if (this.rememberPassword) {
          localStorage.setItem("loginUser", JSON.stringify(accountForm));
        }
        if (this.$router.path != "/index") {
          this.xin.goUrl("/");
        }
        this.showLogin = false;
      }
      this.$loading.hide();
      this.registerWebIm();
    },
    tabShowLogin(showLogin) {
      this.showLogin = showLogin.options ?? !this.showLogin;
    },

    // im注册
    registerWebIm() {
      var options = {
        id: this.userInfo.id,
        username: this.userInfo.id,
        password: this.accountForm.password,
        nickname: this.userInfo.nickname,
        appKey: WebIM.config.appkey,
        success: function () {},
        error: function (err) {
          let errorData = JSON.parse(err.data);
          if (errorData.error === "duplicate_unique_property_exists") {
            console.log("用户已存在！");
          } else if (errorData.error === "illegal_argument") {
            if (errorData.error_description === "USERNAME_TOO_LONG") {
              console.log("用户名超过64个字节！");
            } else {
              console.log("用户名不合法！");
            }
          } else if (errorData.error === "unauthorized") {
            console.log("注册失败，无权限！");
          } else if (errorData.error === "resource_limited") {
            console.log("您的App用户注册数量已达上限,请升级至企业版！");
          }
        },
      };
      WebIM.conn.registerUser(options);
    },

    // 删除图片
    handleRemoveXin(ref, file) {
      for (let i in this.filelists1) {
        if (this.filelists1[i].uid == file.uid) {
          this.filelists1.splice(i, 1);
          break;
        }
      }
    },

    // 上传之前格式判断
    progressImg(file) {
      if (file.type.split("/")[0] != "image") {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
    },

    // 预览图片
    handlePictureCardPreviewXin(file) {
      this.dialogVisibleMax = true;
      this.imgView = file.url;
    },

    // 成功上传图片
    async succseeFile(response, file, fileList) {
      if (response.code == 200) {
        this.filelists1.push(file);
        // 请求成功
      } else {
        this.$message({
          message: response.message,
          type: "error",
        });
        this.isJubaoTWo = false;
        this.$router.go(0);
        this.filelists1 = [];
      }
    },

    //  提交举报内容
    async reportSubmit() {
      if (this.checked) {
        this.reasonInput = this.radio;
        this.isJubaoTWo = true;
        this.isJubao = false;
      } else {
        this.$message.error("请勾选我已阅读并同意《举报须知》");
      }
    },

    //举报
    async submitIsJubao() {
      // 举报理由必填
      if (!this.reasonInput) {
        this.$message.error("请输入举报理由!");
        return;
      }
      let res = await this.$findApi.discoverReport({
        discover_id: this.reporId,
        type: this.type,
        content: this.reasonInput,
        describe: this.reasonText,
        photo: this.filelists1,
        is_report_user: this.userId,
      });

      if (res.code == 200) {
        this.$message.success(res.data);
        this.isJubao = false;
        this.checked = false;
        this.isJubao = false;
        this.isJubaoTWo = false;
        this.reporId = "";
        this.type = "";
        this.reasonInput = "";
        this.userId = "";
        this.filelists1 = [];
        this.reasonText = "";
      } else {
        this.$message.error(res.message);
      }
    },

    // 登录
    // login(type) {
    // 	this.$loading.show()
    // 	this[`${type}Login`]({
    // 		...this[type]
    // 	}).then(token => {
    // 		this.saveLogin(token).then(() => {
    // 			this.$message({ message: '登陆成功', type: 'success' })
    // 			this.showLogin = false
    // 		})
    // 	}).finally(() => this.$loading.hide())
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.clearfix {
  span {
    font-size: 30px;
  }

  div {
    margin-top: 10px;
    font-size: 16px;
  }
}

.radio {
  div {
    margin: 10px 0;
  }
}

.header-box {
  z-index: 2001 !important;
}

.img-rem {
  width: 100%;
  height: 100%;
}

// 输入框样式
header /deep/ .el-input {
  height: 44px;
  background: #f2f2f2;
  border: none;
  border-radius: 44px;

  // overflow: hidden;
  .el-input__inner {
    height: 100%;
    border: none;
    background: none;
    border: 0;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 44px;
  }
}

// 意见反馈
/deep/ {
  .opinion {
    position: relative;

    .el-icon-close {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 20px;
      cursor: pointer;
      color: #333;
    }

    border-radius: 20px;

    .el-dialog__body {
      padding: 50px;
      color: #333;
      font-weight: 400;

      .title {
        font-weight: 400;
        text-align: left;
        color: #333333;
        font-size: 24px;
      }

      .input-name {
        margin: 30px 0 20px 0;

        span {
          color: #8f8f8f;
        }
      }

      textarea {
        padding: 10px 20px;
        min-height: 100px;
        border-radius: 10px;
        background: #f2f2f2;
        border: none;
        font-size: 16px;
        width: 700px;
        min-width: 700px;
        max-width: 700px;
        max-height: 800px;
        box-sizing: border-box;
      }

      .images-box {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        border-bottom: #f2f2f2 1px solid;

        .imgs {
          position: relative;
          width: 150px;
          height: 150px;
          border-radius: 5px;
          margin-top: 22px;
          margin-right: 32px;

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:hover .rowp {
            opacity: 1;
            // transition: all 1s linear;
          }

          .rowp {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #0005;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            color: #fff;
            opacity: 0;
            transition: opacity 0.3s;
            border-radius: 5px;

            span {
              padding: 5px;
              font-size: 24px;
              cursor: pointer;
            }
          }

          img {
            border-radius: 5px;
            display: block;
            width: 150px;
            height: 150px;
            object-fit: cover;
          }
        }

        .add-img {
          margin-top: 22px;
          display: flex;
          cursor: pointer;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 150px;
          color: #bbb8b8;
          border: 1px solid #d9d9d9;
          border-radius: 5px;

          input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }

          .el-icon-plus {
            margin-bottom: 10px;
            font-size: 50px;
          }
        }
      }

      .input-phone {
        background: #f2f2f2;
        height: 32px;
        border-radius: 10px;
        padding: 10px 20px;

        input {
          font-size: 16px;
          height: 100%;
          width: 100%;
          background: none;
          outline: none;
          border: none;
        }
      }

      .btns {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;

        .el-button--default {
          border: $--themeColor 1px solid;
          color: $--themeColor;
        }

        .el-button {
          width: 170px;
          height: 50px;
          border-radius: 26px;
          font-size: 18px;
        }
      }
    }
  }
}

main {
  width: 100vw;
  min-width: 1400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 70px;

  > header {
    display: block !important;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 1400px;
    z-index: 2001;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;

    ::v-deep {
      .searchInput {
        cursor: pointer;
        width: 300px;
        height: 32px;
        margin-left: auto;
      }

      .el-input {
        height: 32px;
        border-radius: 32px;

        .el-input__icon {
          line-height: 32px;
        }
      }

      .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
      }
    }

    > section {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 20px;
      color: #333333;
      font-weight: 500;

      .personal-img {
        margin-left: 50px;
        position: relative;
        cursor: pointer;

        img {
          width: 25px;
          height: 30px;
        }

        .icon-personal {
          position: absolute;
          right: -10px;
          top: 0px;
          width: 16px;
          height: 16px;
          background: #e60012;
          color: #fff;
          border-radius: 50%;
          font-size: 10px;
          text-align: center;
          line-height: 16px;
        }
      }

      > .logo {
        width: 50px;
        height: 50px;
        object-fit: cover;
        cursor: pointer;
      }

      > nav {
        height: 100%;
        box-sizing: border-box;
        display: flex;

        > span {
          height: 100%;
          line-height: 70px;
          margin-left: 50px;
          box-sizing: border-box;
          cursor: pointer;

          &.active {
            border-bottom: 2px solid $--themeColor;
          }
        }
      }

      > .search {
        z-index: 10;
        width: 20px;
        height: 20px;
        object-fit: cover;
        margin-left: -40px;
        margin-top: 5px;
      }

      > .news {
        width: 25px;
        height: 30px;
        object-fit: cover;
        margin-left: 50px;
      }

      > .portrait {
        display: flex;
        align-items: center;
        margin-left: 30px;

        > span {
          font-size: 20px;
          color: #333333;
          cursor: pointer;

          &.line {
            margin: 0 15px;
          }
        }

        .doctor {
          cursor: pointer;
          width: 32px;
          height: 32px;
          // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid #f2f2f2;
          border-radius: 50%;
        }
      }
    }

    > .suspension {
      position: fixed;
      z-index: 100;
      display: flex;
      flex-direction: column;
      // width: 70px;
      border-radius: 7px;
      box-shadow: 0px 3px 6px 0px rgba(53, 43, 92, 0.3);
      color: #352b5c;
      background: #ffffff;
      font-size: 12px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);

      div {
        cursor: pointer;

        &:last-child {
          border-bottom: 0;
        }

        padding: 12px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .clothing {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }

        span {
          margin-top: 1px;
        }
      }
    }
  }

  > footer {
    .header-main {
      background: $--themeColor;
      height: 244px;
      display: flex;
      flex-direction: row;
      align-items: center;

      // margin-top: 100px;
      > section {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;
        color: #333333;

        > .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #ffffff;
          font-size: 14px;

          > .icon {
            width: 122px;
            height: 122px;
            object-fit: cover;
            margin-bottom: 10px;
          }
        }

        > .contact {
          display: flex;
          flex-direction: column;
          color: #ffffff;
          margin-left: 55px;
          margin-top: 30px;
          font-size: 18px;

          > span {
            margin-bottom: 23px;
            white-space: nowrap;
          }

          > .title {
            font-size: 24px;
            margin-bottom: 15px;
          }
        }

        > .address {
          display: flex;
          flex-direction: column;

          > .contact2 {
            &:last-child {
              margin-top: 0;
            }

            display: flex;
            flex-direction: column;
            color: #ffffff;
            margin-left: 90px;
            margin-top: 50px;
            font-size: 18px;
            margin-bottom: 23px;
            margin-top: 96px;

            > .english {
              margin-top: 7px;
              margin-left: 56px;
            }
          }
        }
      }
    }

    > .statement {
      height: 110px;
      background: #f2f2f2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #333333;

      > .one {
        margin-bottom: 18px;
      }
    }
  }
}

// .el-dialog {
// 	> .el-dialog__header {
// 		display: none;
// 	}
// 	> .el-dialog__body {
// 		padding: 0;
// 		display: flex;
// 		flex-direction: column;
// 		&::before {
// 			content: '';
// 			display: block;
// 			height: 2px;
// 			width: 100%;
// 			background-color: $--themeColor;
// 		}
// 	}
// }

.chengshen {
  ::v-deep {
    .text-container {
      width: 100% !important;
    }

    .el-dialog {
      display: flex;
      flex-direction: column;
      padding: 70px 80px;
      box-sizing: border-box;
      margin-top: 320px;

      > .el-dialog__body {
        font-size: 24px;
        color: #333333;
        text-align: center;
      }

      .popupsButton {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        > .popupsJudgment {
          &:last-child {
            background: $--themeColor;
            border: none;
            color: #ffffff;
          }

          width: 170px;
          height: 50px;
          background: #ffffff;
          border: 1px solid $--themeColor;
          border-radius: 26px;
          color: $--themeColor;
          font-size: 18px;
          text-align: center;
          line-height: 50px;
          margin-top: 60px;
        }
      }
    }

    .el-carousel__item .banner {
      width: 890px;
      height: 400px;
      object-fit: cover;
    }

    .el-carousel__arrow {
      display: none;
    }
  }
}

::v-deep {
  .login {
    border-radius: 0;

    > .el-dialog__body {
      &::before {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        background-color: $--themeColor;
      }
    }
  }
}

.login {
  > .el-dialog__body {
    &::before {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background-color: $--themeColor;
    }
  }

  .tabType {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 5px;
    width: 52px;
    height: 52px;

    // span {
    // 	display: block;
    // 	width: 52px;
    // 	height: 52px;
    // }
    img {
      width: 52px;
      height: 52px;
    }
  }

  .section {
    box-sizing: border-box;
    padding: 40px 100px 53px;
    display: flex;
    flex-direction: column;

    .form {
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      /deep/.el-input--prefix {
        color: #8f8f8f;
        background: #f2f2f2;
        border-radius: 44px;

        .el-input__inner {
          border: none;
          background: none;
        }
      }

      > .password {
        margin-top: 20px;
      }

      > .code {
        margin-top: 20px;

        ::v-deep .el-input-group__append {
          background: none;
          border: none;
          color: $--themeColor;
          cursor: pointer;
        }
      }

      > .remark {
        margin-top: 10px;
        display: flex;

        > .forgotPassword {
          font-size: 14px;
          line-height: 22px;
          color: #333333;
          flex: 1;
        }
      }

      > .el-button {
        margin-top: 10px;
        height: 44px;
        border-radius: 44px;
        border: none;
        background-color: $--themeColor;
        color: white;
        font-size: 16px;
      }

      .readAndAgree {
        margin: 20px auto 0;
      }
    }

    .QR-Login {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 220px;
      padding: 10px;

      .qr-img {
        background: #c5c7ca;
        border-radius: 10px;
        width: 200px;
        height: 200px;

        .image-slot {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #fff;
          font-size: 30px;
          cursor: pointer;

          div {
            margin-top: 10px;
            font-size: 18px;
          }
        }
      }

      > span {
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
        color: #333333;
      }
    }

    .noAccount {
      margin: 20px auto 0;
      display: flex;

      > span {
        font-size: 14px;
        line-height: 22px;
        color: #333333;

        &.register {
          color: $--themeColor;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.dialogDiv {
  border-radius: 20px;
  padding-left: 20px;
  height: 446rpx;
  width: 635px;
  box-shadow: border-box;

  .title {
    // font-size: 28px;
    // font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    // color: #333333;
    // line-height: 40px;
    // padding-top: 40px;
    // box-shadow: border-box;

    // width: 120px;
    // height: 42px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    line-height: 42px;
    padding-top: 20px;

    box-shadow: border-box;
  }

  .messageDiv {
    margin-top: 20px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 595px;
    height: 60px;
    background: #f2f2f2;
    border-radius: 5px;

    img {
      width: 19px;
      height: 19px;
      padding: 26px 20px;
      box-shadow: border-box;
    }

    .messageDivContentDiv {
      // width: 720px;
      // height: 56px;
      // font-size: 20px;
      // font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      // color: #FB7A5F;
      // line-height: 28px;

      width: 557px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f98366;
      line-height: 20px;
    }
  }

  .reasonDiv {
    width: 595px;
    height: 40px;
    margin-top: 25px;
    border-bottom: 1px #ececec solid;

    .titleCheng {
      display: flex;
      flex-direction: row;

      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 20px;

      .titleInput {
        // width: 80px;
        // height: 32px;
        // font-size: 20px;
        // font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 400;
        // color: #333333;
        // line-height: 32px;

        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        line-height: 20px;
      }

      .titleInputMagin {
        margin-left: 108px;
        border: none;
        outline: none;
        width: 200px;
      }
    }
  }

  .problemDiv {
    // width: 134px;
    // height: 32px;
    // font-size: 20px;
    // font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    // color: #333333;
    // line-height: 32px;
    margin-top: 17px;

    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    // color: #76797D;
    line-height: 20px;

    span {
      color: #a2a2a2;
      font-size: 16px;
    }

    /deep/.el-textarea__inner {
      width: 600px;
      padding: 0;
      margin: 0;
    }

    /deep/.el-input__inner {
      border: 0;
    }

    .problemDivInput {
      // margin-top: 17px;
      // margin-bottom: 17px;
      padding: 0;
      padding: 0;
      margin-top: 20px;
      outline: none;

      /deep/.el-textarea__inner {
        border: 0;
        outline: none;
      }
    }
  }

  // .uploadButtonDiv {
  // 	height: 153px;
  // 	width: 820px;
  // 	// background-color: red;
  // 	margin-top: 40px;
  // 	border-top: 1px solid #ececec;

  .buttonDiv {
    width: 635px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    // background-color: red;

    button {
      width: 70px;
      height: 40px;
      background: #30dbc6;
      border-radius: 5px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      border: none;
    }
  }

  // }

  .uploadImgCheng {
    width: 25px;
    height: 25px;
    // display: block;
  }
}

.isJubao /deep/.el-dialog {
  // border-radius: 20px;
  width: 635px;
}

.chengDialog /deep/.el-dialog {
  // border-radius: 20px;
  padding: 70px 80px;
  box-sizing: border-box;
}

.chengJuOne /deep/.el-dialog {
  width: 635px;
}

.fabu /deep/.el-upload--picture-card {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}

.fabu /deep/.el-upload-list__item {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}

.bigXinView {
  padding: 70px;
}

.cancellation {
  box-sizing: border-box;
  padding: 40px 100px 53px;
  display: flex;
  flex-direction: column;
  .form {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    > .account {
      margin-top: 30px;
    }

    /deep/.el-input--prefix {
      color: #8f8f8f;
      background: #f2f2f2;
      border-radius: 44px;

      .el-input__inner {
        border: none;
        background: none;
      }
    }

    > .password {
      margin-top: 20px;
    }

    > .code {
      margin-top: 20px;

      ::v-deep .el-input-group__append {
        background: none;
        border: none;
        color: $--themeColor;
        cursor: pointer;
      }
    }

    > .remark {
      margin-top: 10px;
      display: flex;

      > .forgotPassword {
        font-size: 14px;
        line-height: 22px;
        color: #333333;
        flex: 1;
      }
    }

    > .el-button {
      margin-top: 10px;
      height: 44px;
      border-radius: 44px;
      border: none;
      background-color: $--themeColor;
      color: white;
      font-size: 16px;
    }

    .readAndAgree {
      margin: 20px auto 0;
    }
  }
}
.cancellationTitle {
  font-size: 24px;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 33px;
  color: #333333;
  opacity: 1;
  margin: 0 auto;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cancellationTitle div {
  margin-bottom: 11px;
}
.cancellationLine {
  width: 200px;
  height: 2px;
  background: #03dbc6;
  opacity: 1;
}

.cancellation {
  /deep/.el-button {
    margin-top: 20px !important;
  }

  /deep/ .el-input {
    height: 44px;
    background: #f2f2f2;
    width: 360px !important;
    border: none;
    border-radius: 44px;

    // overflow: hidden;
    /deep/.el-input__inner {
      width: 360px !important;
      border: none;
      background: none;
      border: 0;
      border-radius: 0;
    }

    /deep/.el-input__icon {
      line-height: 44px;
    }
  }
}
.cancellationButton {
  height: 44px;

  width: 360px !important;
  margin-top: 20px !important;
}
.cancellation .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chengSuccessImg {
  height: 136px;
  width: 136px;
  margin-top: 64px;
}
.chengSuccessDiv {
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 45px;
  color: #24dbc6;
  opacity: 1;
  margin-top: 26px;
}
.chengShen {
  position: fixed !important ;
  top: 50px !important ;
  z-index: 2001 !important ;
}
</style>