import Vue from "vue";

// 全局引入filed组件并注册
import field from "@components/common/field";
Vue.component("field", field);

/********************************* 第三方 *******************************/
// element
import "./elementUi";
// 暂时没有发现使用的地方
// import { InfiniteScroll } from "element-ui";
// Vue.component(InfiniteScroll.name, InfiniteScroll);

// 日期处理时间,格式化插件
import moment from "moment";
moment.locale("zh-cn"); //设置时区
Vue.prototype.$moment = moment;
//  全局过滤器(暂时没有发现使用的地方)
// Vue.filter("dateFormat", function(dataStr, pattern = "YYYY-MM-DD HH:mm:ss") {
//   if (dataStr && dataStr.toString().length) {
//     dataStr = dataStr * 1000;
//   }
//   return moment(dataStr).format(pattern);
// });

// VueQuillEditor
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
Vue.use(VueQuillEditor);

//分享 QQ 微信 微博
import Share from "vue-social-share";
import "vue-social-share/dist/client.css";
import "../../node_modules/social-share.js/dist/js/social-share.min.js";
import "../../node_modules/social-share.js/dist/css/share.min.css"
Vue.use(Share);

// 图片懒加载
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("@assets/image/imgError.png"),
  loading: require("@assets/image/loading.gif"),
  attempt: 1,
});

// 图片预览
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
Vue.use(preview);

/********************************* 第三end *******************************/

/********************************* 公共方法 *******************************/
// 引入请求信息
import http from "@/utils/api/http";
import "@/utils/api/model/apiModel";
Vue.prototype.$http = http;

// 公共xin方法的封装
import xin from "@/utils/xin";
Vue.prototype.xin = xin;

// 引入全局过滤器
import * as filters from "@/utils/time";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 引入 im
import WebIM from "@/utils/Im/initWeb";
Vue.prototype.$WebIM = WebIM;

/********************************* 公共方法end *******************************/

export { moment };
