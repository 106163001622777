import http from "@/utils/api/http";

export default {
  // 添加修改学习计划
  getStydyPlan(data) {
    return http.post("/dkt/study/plan", data);
  },
  // 获取所有学习计划
  getAllStudyPlan(data) {
    return http.post("/dkt/study/allStudyPlan", data);
  },
  // 获取指定日期的学习计划
  getPlan(data) {
    return http.post("/dkt/study/studyPlan", data);
  },
  // 删除学习计划
  getDelPlan(data) {
    return http.post("/dkt/study/del_plan", data);
  },
};
