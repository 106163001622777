export default [
  {
    name: "refresh",
    path: "/refresh",
    hideInMenu: true,
    component: () => import("@view/common/refresh.vue"),
  },
  // 注册
  {
    name: "register",
    path: "/register",
    meta: {
      title: "注册",
    },
    component: () => import("@view/common/register.vue"),
  },
  // 忘记密码
  {
    name: "forgotPassword",
    path: "/forgotPassword",
    meta: {
      title: "忘记密码",
    },
    component: () => import("@view/common/forgotPassword.vue"),
  },
  // 个人中心
  {
    name: "personalCenter",
    path: "/personalCenter",
    meta: {
      title: "个人中心",
      requireAuth: true,
    },
    component: () => import("@view/common/personalCenter.vue"),
  },
  // 身份认证
  {
    name: "authentication",
    path: "/authentication",
    meta: {
      title: "身份认证",
      requireAuth: true,
      keepAlive: true,
    },
    component: () => import("@view/common/authentication.vue"),
  },
  // 提现
  {
    name: "withdraw",
    path: "/withdraw",
    meta: {
      title: "提现",
      requireAuth: true,
    },
    component: () => import("@view/common/withdraw.vue"),
  },
  // 设置
  {
    name: "setting",
    path: "/setting",
    meta: {
      title: "设置",
      requireAuth: true,
    },
    component: () => import("@view/common/setting.vue"),
  },

  {
    name: "settingTwo",
    path: "/settingTwo",
    meta: {
      title: "设置",
      requireAuth: true,
    },
    component: () => import("@view/common/settingTwo.vue"),
  },

  // 个人主页
  {
    name: "homepage",
    path: "/homepage",
    meta: {
      title: "个人主页",
      requireAuth: true,
    },
    component: () => import("@view/common/homepage.vue"),
  },
  // 个人中心
  {
    name: "userCenter",
    path: "/userCenter",
    meta: {
      title: "个人中心",
      requireAuth: true,
    },
    component: () => import("@view/common/userCenter.vue"),
  },
  // 消息通知
  {
    name: "notification",
    path: "/notification",
    meta: {
      title: "消息通知",
      requireAuth: true,
    },
    component: () => import("@view/common/notification.vue"),
  },

  // 我的信誉分
  {
    name: "myCreditScore",
    path: "/myCreditScore",
    meta: {
      title: "我的信誉分",
      requireAuth: true,
    },
    component: () => import("@view/common/myCreditScore.vue"),
  },

  // 点赞分享列表页
  {
    name: "shareLike",
    path: "/shareLike",
    meta: {
      title: "点赞分享列表页",
      requireAuth: true,
    },
    component: () => import("@view/common/shareLike.vue"),
  },
];
