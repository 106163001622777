import Vue from "vue";
// import createPersistedState from "vuex-persistedstate";

let that = Vue.prototype;
const Im = {
  namespaced: true,
  state: {
    WebIM: "", // IM 示例
    ImUserData: "",
    friendList: [], // 好友列表
    chatList: {}, // 聊天记录
    msgList: [], // im 消息列表
    avatarData: {}, //  用户头像昵称库
    connectionFlag: false, //链接状态
    delList: [], // 删除会话
    loginFlag: false, // 登录状态
    appkey: "1109210408203518#dazhongyaoping",
    doctorData: localStorage.getItem("doctorData") || [], // 医生数据
    playFlag: false,
    chatId: "",
  },
  getters: {
    chatData(state) {
      return state.chatList[state.chatId];
    },
  },
  mutations: {
    updataChatId(state, data) {
      state.chatId = data;
      if (!state.chatList[state.chatId]) {
        state.chatList[state.chatId] = [];
      }
    },
    updataLoginFlag(state, data) {
      state.loginFlag = data;
    },
    updatePlayRadio(state, data) {
      state.playFlag = data;
      setTimeout(() => {
        state.playFlag = false;
      }, 1000);
    },
    updateAvatarData(state, data) {
      state.avatarData = data;
    },
    updateMsgList(state, data) {
      state.msgList = data;
    },
    updataUserChatList(state, data) {
      // 更新用户的整个聊天记录 --- 云端
      let chatList = JSON.parse(JSON.stringify(state.chatList));
      let { id, res } = data;
      res.length ? that.$message.success(`同步到${res.length}条云端记录`) : "";
      chatList[id] = res;
      state.chatList = chatList;
    },
    updateChatList(state, data) {
      let chatList = JSON.parse(JSON.stringify(state.chatList));
      let id = data.from == state.ImUserData.username ? data.to : data.from;
      // 更新聊天记录
      let chatData = chatList[id] || [];
      chatData.push(data);
      chatList[id] = chatData;
      state.chatList = chatList;
      // 更新msglist 的最后一条数据
      let channel_id = state.appkey + "_" + id + "@easemob.com";
      let choosFlag = false;
      try {
        state.msgList.forEach((item, index) => {
          if (item.channel_id === channel_id) {
            choosFlag = true;
            state.msgList[index].meta.payload.bodies[0].msg = data.data;
            state.msgList[
              index
            ].meta.payload.bodies[0].type = data.contentsType.toLowerCase();
            let newArr = item.channel_id.split("@");
            let id = newArr[0].substring(state.appkey.length + 1, str.length);
            if (id * 1 !== state.chatId * 1) {
              // 未读消息 +1
              state.msgList[index].unread_num += 1;
            }
            throw new Error();
          }
        });
      } catch (err) {}
      if (!choosFlag) {
        // 当前列表不存在该用户 刷新昵称列表
        // that.$WebIM.conn.getSessionList().then((res) => {
        // let {channel_infos} = res.data
        // channel_infos.forEach((item,index) => {
        //     item.meta.payload = item.meta.payload ? JSON.parse(item.meta.payload) : ''
        // })
        // // 更新msgList
        // state.msgList =  channel_infos
        // // 查询头像昵称库
        // }).catch((err)=>{
        //     that.xin.Toast.error('列表刷新失败')
        // })
        let obj = {
          channel_id,
          unread_num: 1,
          meta: {
            timestamp: data.time,
            payload: {
              from: data.from,
              to: data.to,
              bodies: [
                { msg: data.data, type: data.contentsType.toLowerCase() },
              ],
            },
          },
          dec: "本地生成数据",
        };
        state.msgList.unshift(obj);
      }
    },
    updateFrienddList(state, data) {
      state.friendList = data;
    },
    updateImUserData(state, data) {
      state.ImUserData = data;
    },
    updatedoctorData(state, data) {
      localStorage.setItem("doctorData", data);
      state.doctorData = data;
    },
  },
};
export default Im;
