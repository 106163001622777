export default [
  // 发现
  {
    name: "found",
    path: "/found",
    meta: {
      title: "发现",
      keepAlive: true,
    },
    component: () => import("@view/tabBar/found.vue"),
  },
  // 发现->个人发布
  {
    name: "found-personal",
    path: "/found-personal",
    meta: {
      title: "个人发布",
    },
    component: () => import("@view/found/personal/index.vue"),
  },
  // 发现->发布
  {
    name: "found-release-index",
    path: "/found-release-index",
    meta: {
      title: "发布",
      requireAuth: true,
    },
    component: () => import("@view/found/release/index.vue"),
  },
  // 发现->视频详情
  {
    name: "found-details-videoDetails",
    path: "/found-details-videoDetails",
    meta: {
      title: "视频详情",
    },
    component: () => import("@view/found/details/videoDetails .vue"),
  },
  // 发现->详情
  {
    name: "found-details-details",
    path: "/found-details-details",
    meta: {
      title: "详情",
    },
    component: () => import("@view/found/details/details.vue"),
  },
  // 发现->问答
  {
    name: "found-qa",
    path: "/found-qa",
    meta: {
      title: "问答",
    },
    component: () => import("@view/found/qa"),
  },
  // 发现->问答->详情
  {
    name: "found-qa-details",
    path: "/found-qa-details",
    meta: {
      title: "详情",
    },
    component: () => import("@view/found/qa/details.vue"),
  },

  // 发现->指南解析
  {
    name: "found-guideAnalysis",
    path: "/found-guideAnalysis",
    component: () => import("@view/found/navPage/guideAnalysis.vue"),
  },

  // 发现->病例分享
  {
    name: "found-caseSharing",
    path: "/found-caseSharing",
    component: () => import("@view/found/navPage/caseSharing.vue"),
  },
  // 发现->论文分享
  {
    name: "found-paperSharing",
    path: "/found-paperSharing",
    component: () => import("@view/found/navPage/paperSharing.vue"),
  },
];
