<template>
  <div id="app">
    <common ref="common">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </common>
  </div>
</template>

<script>
import common from "@components/common";
export default {
  components: {
    common,
  },
  name: "App",
  created() {
    //  解决刷新页面导致vuex中state状态丢失问题
    if (sessionStorage.getItem("store")) {
      // 替换store的根状态
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>


