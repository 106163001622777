export default [
  // 直播列表页面
  {
    name: "liveBroadcastList",
    path: "/liveBroadcastList",
    meta: {
      title: "直播列表",
      requireAuth: true,
    },
    component: () => import("@view/liveBroadcast/liveBroadcastList.vue"),
  },
  // 直播详情页面
  {
    name: "liveBroadcastDetail",
    path: "/liveBroadcastDetail",
    meta: {
      title: "直播详情页",
      requireAuth: true,
    },
    component: () => import("@view/liveBroadcast/liveBroadcastDetail.vue"),
  },
];
