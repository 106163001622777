export default [
  // 创作
  {
    name: "creation",
    path: "/creation",
    meta: {
      title: "创作",
    },
    component: () => import("@view/tabBar/creation.vue"),
  },
  // 创作->编辑发布
  {
    name: "creation-editRelease",
    path: "/creation-editRelease",
    meta: {
      title: "编辑发布",
    },
    component: () => import("@view/creation/editRelease.vue"),
  },
  // 创作->编辑详情
  {
    name: "creation-creationDetails",
    path: "/creation-creationDetails",
    meta: {
      title: "编辑详情",
    },
    component: () => import("@view/creation/creationDetails.vue"),
  },
];
