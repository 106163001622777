import Vue from "vue";
//  login Api
import userApi from "@/utils/api/model/user/userApi.js";
import newsApi from "@/utils/api/model/news/newsApi.js";
import loginApi from "@/utils/api/model/login/loginApi.js";
import findApi from "@/utils/api/model/find/findApi.js";
import personalCenter from "@/utils/api/model/personalCenter/personalCenter.js";
import creationApi from "@/utils/api/model/creation/creationApi.js";
import researchApi from "@/utils/api/model/research/researchApi.js";
import learningApi from "@/utils/api/model/learning/learningApi.js";
import liveBroadcastApi from "@/utils/api/model/liveBroadcast/liveBroadcastApi.js";

//  挂载
Vue.prototype.$userApi = userApi;
Vue.prototype.$newsApi = newsApi;
Vue.prototype.$loginApi = loginApi;
Vue.prototype.$findApi = findApi;
Vue.prototype.$personalApi = personalCenter;
Vue.prototype.$creationApi = creationApi;
Vue.prototype.$researchApi = researchApi;
Vue.prototype.$learningApi = learningApi;
Vue.prototype.$liveBroadcastApi = liveBroadcastApi;
