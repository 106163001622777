import http from "@/utils/api/http";

export default {
  // 创作列表
  creationList(data) {
    return http.post("/dkt/discover/creation", data);
  },

  // 搜索（综合）
  searchAll(data) {
    return http.post("/dkt/home/search_synthesize", data);
  },
  // 搜索（视频、用户、调研）
  searchList(data) {
    return http.post("/dkt/home/search", data);
  },
};
