import http from "@/utils/api/http";

export default {
  //获取用户详情
  getUserInfo(data) {
    return http.post("/dkt/user/info", data);
  },

  //获取用户微信二维码
  getSmallCode(data) {
    return http.post("/dkt/Wxcallback/getSmallCode", data);
  },

  // 修改个人专长简介
  postUpdateSynopsis(data) {
    return http.post("/dkt/user/update_synopsis", data);
  },

  // 职业信息科研信息学术论文公益活动 添加
  postUpdateAddMore(data) {
    return http.post("/dkt/user/add_more", data);
  },

  // 职业信息科研信息学术论文公益活动 删除
  postUuserDeleteMore(data) {
    return http.post("/dkt/user/delete_more", data);
  },

  // 职业信息科研信息学术论文公益活动 修改
  postUuserUpdateMore(data) {
    return http.post("/dkt/user/update_more", data);
  },

  // 修改密码第一步
  postQueryMobile(data) {
    return http.post("/dkt/user/query_mobile", data);
  },

  // 修改密码第二步修改密码
  postQueryPassword(data) {
    return http.post("/dkt/user/update_password", data);
  },
  // 修改手机号
  postUpdateMobile(data) {
    return http.post("/dkt/user/update_mobile", data);
  },

  // 修改用户信息
  postUpdateInfo(data) {
    return http.post("/dkt/user/update_info", data);
  },

  // 注销账号
  postUpdateDel(data) {
    return http.post("/dkt/user/del_user", data);
  },

  //银行列表
  getBankList(data) {
    return http.post("/dkt/user/bank_list", data);
  },

  //获取收款详细信息
  getBankInfo(data) {
    return http.post("/dkt/user/get_bank", data);
  },

  // 解绑
  postUpdateDelBank(data) {
    return http.post("/dkt/user/del_bank", data);
  },

  // 设置默认收款账号
  postSetDefaultBank(data) {
    return http.post("/dkt/user/set_default_bank", data);
  },

  // 添加银行卡
  postAddBank(data) {
    return http.post("/dkt/user/add_bank", data);
  },

  //获取所有银行卡
  getListBank(data) {
    return http.post("/dkt/user/get_list_bank", data);
  },

  del_bank(data) {
    return http.post("/dkt/user/del_bank", data);
  },

  //个人中心信息
  getPersonalUserInfo(data) {
    return http.post("/dkt/personal/user", data);
  },

  //我的二维码
  getPersonalUserCode(data) {
    return http.post("/dkt/user/user_code", data);
  },

  //2:我的关注		1:我的粉丝
  getPersonalUser(data, type) {
    if (type == 1) {
      return http.post("/dkt/personal/fans", data);
    } else {
      return http.post("/dkt/personal/follow", data);
    }
  },

  //消息通知
  getPersonalNotice(data) {
    return http.post("/dkt/personal/notice", data);
  },

  //新消息列表
  getPersonalNewNotice(data) {
    return http.post("/dkt/personal/newNotice", data);
  },

  //通知设置参数
  getNoticeUserInfo(data) {
    return http.post("/dkt/personal/noticeUserInfo", data);
  },

  //设置消息通知
  postPersonalNoticeStatus(data) {
    return http.post("/dkt/personal/noticeStatus", data);
  },

  //我的收藏/历史 		类型：1收藏，2评论，3点赞，4历史
  postPersonalCollect(data) {
    return http.post("/dkt/personal/collect", data);
  },

  // 删除收藏
  postPersonalDelColl(data) {
    return http.post("/dkt/personal/del_collect", data);
  },

  // 删除全部收藏
  postPersonalDelAll(data) {
    return http.post("/dkt/personal/delCollectAll", data);
  },

  // 诊疗日志添加
  postOperationAdd(data) {
    return http.post("/dkt/operation/add", data);
  },

  // 诊疗日志列表
  getOperationList(data) {
    return http.post("/dkt/operation/lists", data);
  },

  // 诊疗日志详情
  getOperationInfo(data) {
    return http.post("/dkt/operation/info", data);
  },

  //我的创作
  getPersonalCreate(data) {
    return http.post("/dkt/personal/create", data);
  },

  // 关注用户
  postDiscoverAttention(data) {
    return http.post("/dkt/discover/attention", data);
  },
  //是否关注某用户
  postDiscoverIsAttention(data) {
    return http.post("/dkt/discover/isAttention", data);
  },
  // 议价反馈
  postPersonalFeedback(data) {
    return http.post("/dkt/personal/feedback", data);
  },

  // 我的调研
  postQuestionnaire(data) {
    return http.post("/api/questionnaire/questionnaire_list", data);
  },
  // 调研取消预约
  postCancel_investigation(data) {
    return http.post("/api/questionnaire/cancel_investigation", data);
  },
  // 绑定微信公众号
  postLoginBin_wx(data) {
    return http.post("/dkt/login/bind_wx", data);
  },
  // 生成二维码
  postGenerateTwoQrcode(data) {
    return http.post("/dkt/login/login_qrcode", data);
  },
  // 轮询二维码
  getLoginQuery(data) {
    return http.get("/dkt/login/query", data);
  },
  wxDoLogin(data) {
    return http.post("/dkt/login/login_wechat", data);
  },
  // 文件上传
  postFilesUpload(data) {
    return http.post("/dkt/other/upload", data, true);
  },
  // 发现删除
  postDelDiscover(data) {
    return http.post("/dkt/discover/delDiscover", data);
  },
  // 诊疗日志删除
  operationdelete(data) {
    return http.post("/dkt/operation/delete", data);
  },

  //我的收益
  getPersonalProfit(data) {
    return http.post("/dkt/personal/profit", data);
  },
  //提现
  getAdd_revenue_record(data) {
    return http.post("/dkt/personal/add_revenue_record", data);
  },

  // //收款记录
  // getAddProfitList(data) {
  //   return http.post("/dkt/personal/profit_list", data);
  // },

  // // 余额提现
  // getPayment(data) {
  //   return http.post("/dkt/survey/payment", data);
  // },

  // 提现规则
  getRuleText(data) {
    return http.post("/dkt/survey/rule_text", data);
  },

  // 新收益列表
  getProfitNew(data) {
    return http.post("/dkt/survey/profit_new", data);
  },

  // 提现
  platformPayment(data) {
    return http.post("/dkt/survey/stage_payment", data);
  },

  // 个税
  stageFee(data) {
    return http.post("/dkt/survey/stage_fee", data);
  },

  // 发送验证码
  sendCode(data) {
    return http.post("/dkt/survey/send_code ", data);
  },

  // 合并收益 - 网医可提现列表
  wyRewardList(data) {
    return http.post("/dkt/survey/wy_reward_list ", data);
  },

  // 合并收益 - 网医收益提现记录
  wyProfitList(data) {
    return http.post("/dkt/survey/wy_reward_detail ", data);
  },

  // 创作收益列表
  // wyProfitNew(data) {
  //   return http.pots("/dkt/survey/wy_profit_new", data);
  // },

  // // 调研收益列表
  // rewardProfitNew(data) {
  //   return http.pots("/dkt/Survey/reward_profit_new", data);
  // },
};
