import Vue from "vue";
// import Vuex, { createLogger } from "vuex";
import Vuex from "vuex";
import Im from "./modules/Im/Im";
Vue.use(Vuex);

const modulesFiles = require.context("./modules", true, /index\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(
    /^\.\/(.*)\/index\.\w+$/,
    "$1Management"
  );
  const value = modulesFiles(modulePath);
  modules[moduleName] = {
    namespaced: true,
    ...value.default,
  };
  return modules;
}, {});

modules["Im"] = Im;

const store = new Vuex.Store({
  modules,
});

export default store;
