import http from "@/utils/api/http";

export default {
  // 问卷列表废弃 新(https://api.idr.cn/dkt/survey/project)
  // researchList(data) {
  //   return http.post("/api/questionnaire/questionnaire_list", data);
  // },

  // 调研项目列表
  survyProject(data) {
    return http.post("/dkt/survey/project", data);
  },

  // 问卷推荐列表
  recommendList(data) {
    return http.post("/api/questionnaire/recommend_list", data);
  },
  // 添加问卷推荐
  addRecommendList(data) {
    return http.post("/api/questionnaire/add_recommend", data);
  },
  // 问卷详情
  questionnaireDetail(data) {
    return http.post("/api/questionnaire/question_list", data);
  },
  // 调研规则
  questionnaireRule(data) {
    return http.post("/api/questionnaire/questionnaire_rule", data);
  },
  // 调研礼金(接口废弃)
  // questionnaireInfo(data) {
  //   return http.post("/api/questionnaire/questionnaire_info", data);
  // },
  // 参加调研
  addQuestionnaire(data) {
    return http.post("/api/questionnaire/add_investigation", data);
  },
  // 添加用户答题
  addQuestions(data) {
    return http.post("/api/questionnaire/add_questions", data);
  },
  // 判断是否重复调研
  checkQuestions(data) {
    return http.post("/api/questionnaire/check_ids", data);
  },
  //调研推荐医院
  otherHospital(data) {
    return http.post("/dkt/other/hospital", data);
  },
  //删除推荐
  delRecommend(data) {
    return http.post("/api/questionnaire/del_recommend", data);
  },
};
