import vue from "vue";
// import { createLogger } from "vuex";
let _this = vue.prototype;
// 模拟请求接口
// const getUserInfo = () => {
//   _this.$userApi
//     .getUserInfo()
//     .then((res) => {
//       if (res.code == 200) {
//         return res.data;
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//       // this.$message.erroe(err);
//     });
// };

export default {
  // 保存登录信息
  async saveLogin({ state }, { username: phone, password }) {
    // 模拟请求拿到用户信息
    let res = await _this.$loginApi.login({
      phone,
      password,
      type: 1,
    });
    if (res.code == 200) {
      _this.$message({
        message: "登录成功",
        type: "success",
      });
      localStorage.setItem("token", res.data);
      let ret = await _this.$userApi.getUserInfo();
      state.userInfo = ret.data;
      let { id } = state.userInfo;
      try {
        let { appkey } = _this.$WebIM.config;
        let options = {
          user: id,
          pwd: "862359",
          appKey: appkey,
          success: () => {},
          error: () => {
            // "_this.$message.error("Im 登录失败，请检查用户名密码是否正确);
          },
        };
        _this.$WebIM.conn.open(options);
      } catch (err) {
        // _this.$message.error(err);
      }

      let { code, data } = await _this.$userApi.getPersonalUserInfo();
      if (code == 200) {
        state.userParam = data;
      }
      return true;
    } else {
      _this.$message.error(res.message);
      return false;
    }
  },

  // 获取用户信息
  async getUserInfo({ state }) {
    let { data } = await _this.$userApi.getUserInfo();
    state.userInfo = data;
    let { id } = data;
    try {
      let { appkey } = _this.$WebIM.config;
      let options = {
        user: id,
        pwd: "862359",
        appKey: appkey,
        success: () => {},
        error: () => {
          //   _this.$message.error("Im 登录失败，请检查用户名密码是否正确");
        },
      };
      _this.$WebIM.conn.open(options);
    } catch (err) {
      // _this.$message.error(err);
    }
  },

  // 获取个人信息
  async getPersonal({ state }) {
    let { code, data } = await _this.$userApi.getPersonalUserInfo();
    if (code == 200) {
      state.userParam = data;
    }
  },

  // 退出登录
  logout({ state }, blod) {
    localStorage.removeItem("token");
    state.userInfo = {};
    state.userParam = {};
    try {
      _this.$WebIM.conn.close();
    } catch (err) {
      // console.log(err);
    }
    if (!blod) _this.xin.goUrl("/index");
  },
};
