import http from "@/utils/api/http";

export default {
  // 推荐医生列表
  docterList(data) {
    return http.post("/dkt/discover/docter", data);
  },
  // 发现列表
  findList(data) {
    return http.post("/dkt/discover/lists", data);
  },
  //模拟考试
  userExamLists(data) {
    return http.post("/dkt/exam/userExamLists", data);
  },
  //我的模拟
  userBookExam(data) {
    return http.post("/dkt/exam/userBookExam", data);
  },
  //首页推荐
  dislistsInfo(data) {
    return http.post("/dkt/discover/dislists", data);
  },
  // 发现详情
  findDetail(data) {
    return http.post("/dkt/discover/info", data);
  },
  // 发布
  findInfo(data) {
    return http.post("/dkt/discover/release", data);
  },
  // 更新人数
  findNum(data) {
    return http.post("/dkt/discover/updateNum", data);
  },
  //广告新闻接口
  adv_list(data) {
    return http.post("/dkt/news/adv_list", data);
  },
  //发现页面广告接口
  adv_listFound(data) {
    return http.post("/dkt/discover/adv_list", data);
  },

  // 回答
  findAnswer(data) {
    return http.post("/dkt/discover/answer", data);
  },
  // 回答详情
  findAnswerInfo(data) {
    return http.post("/dkt/discover/answerInfo", data);
  },
  // 评论
  findAddComment(data) {
    return http.post("/dkt/discover/addComment", data);
  },
  // 点赞
  findCommentLike(data) {
    return http.post("/dkt/discover/commentLike", data);
  },
  // 收藏
  findCollection(data) {
    return http.post("/dkt/discover/collection", data);
  },
  // 删除
  findDelete(data) {
    return http.post("/dkt/Discover/del_comment", data);
  },
  // 广告
  findAdvertising(data) {
    return http.post("/dkt/discover/advertising", data);
  },
  // 关注
  findAttention(data) {
    return http.post("/dkt/discover/attention", data);
  },
  // 分享
  findShare(data) {
    return http.post("/dkt/discover/forward", data);
  },
  // 通告栏
  findNotice(data) {
    return http.post("/dkt/personal/homeNotice", data);
  },

  //公告栏
  banner(data) {
    return http.post("/dkt/adv/banner", data);
  },

  //举报
  discoverReport(data) {
    return http.post("/dkt/discover/report", data);
  },

  // 17、保存到草稿箱
  findDiscoberReleaseDraft(data) {
    return http.post("/dkt/discover/releaseDraft", data);
  },
  // 18、删除草稿箱
  findDiscoberDelDraft(data) {
    return http.post("/dkt/discover/delDraft", data);
  },
  // 19、草稿箱列表
  findDiscobergetDraftList(data) {
    return http.post("/dkt/discover/getDraftList", data);
  },
  // 20、草稿箱详情
  findDiscobergetDraftInfo(data) {
    return http.post("/dkt/discover/getDraftInfo", data);
  },

  // 首页推荐位接口
  discoverDislists(data) {
    return http.post("/dkt/discover/dislists", data);
  },

  // 发现推荐位接口
  discoverDistolists(data) {
    return http.post("/dkt/discover/distolists", data);
  },

  // 根据类型获取发布列表 		参数:type  1发布说说；2病例解析；3学术视频；4科普词条/指南；5发布问答;11发布论文
  findcreationListByType(data) {
    return http.post("/dkt/discover/create", data);
  },

  // 点赞转发接口
  aboutList(data) {
    return http.post("/dkt/discover/aboutList", data);
  },
  //药品库点赞接口
  drugCommentLike(data) {
    return http.post("/dkt/discover/drugCommentLike", data);
  },
  //药品库收藏接口
  drugCollection(data) {
    return http.post("/dkt/discover/drugCollection", data);
  },
  //药品库评论接口
  drugAddComment(data) {
    return http.post("/dkt/discover/drugAddComment", data);
  },

  // 药品分类列表
  drugMenu(data) {
    return http.post("/dkt/Drug/menu", data);
  },
  // 药品库分类查询
  categoryList(data) {
    return http.post("/dkt/drugs/categoryList", data);
  },

  // 药品详情接口
  drugDetail(data) {
    return http.post("/dkt/drug/detail", data);
  },

  // 药品库分类信息详情查询
  categoryDetail(data) {
    return http.post("/dkt/drugs/categoryDetail", data);
  },

  // 药品开新增详细查询
  categoryAllDetail(data) {
    return http.post("/dkt/drugs/categoryAllDetail ", data);
  },
};
