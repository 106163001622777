// import Vue from "vue";
// let that = Vue.prototype;

import store from "@/store/index";
import websdk from "easemob-websdk";

//初始化IM SDK
import config from "./WebIMConfig";

WebIM.config = config;

// 创建连接
WebIM.conn = new WebIM.connection({
  appKey: WebIM.config.appkey,
  isHttpDNS: WebIM.config.isHttpDNS,
  isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
  host: WebIM.config.Host,
  https: WebIM.config.https,
  url: WebIM.config.socketServer,
  apiUrl: WebIM.config.restServer,
  isAutoLogin: true,
  heartBeatWait: WebIM.config.heartBeatWait,
  autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
  autoReconnectInterval: WebIM.config.autoReconnectInterval,
  isStropheLog: WebIM.config.isStropheLog,
  delivery: WebIM.config.delivery,
});

//实时获取时间
var newDate;
window.onload = function() {
  var show = document.getElementById("show");
  setInterval(function() {
    var time = new Date(); // 程序计时的月从0开始取值后+1
    var m = time.getMonth() + 1;
    var t =
      time.getFullYear() +
      "-" +
      m +
      "-" +
      time.getDate() +
      " " +
      time.getHours() +
      ":" +
      time.getMinutes() +
      ":" +
      time.getSeconds();
    newDate = t;
  }, 1000);
};

//注册IM回调
WebIM.conn.listen({
  // 连接成功
  onOpened: function() {
    store.commit("Im/updataLoginFlag", true);
    // that.xin.goUrl('/index')
  },
  // 连接断开
  onClosed: function() {
    //  that.$message.error('连接断开')
    //  store.commit('Im/updataLoginFlag',false)
  },
  // 收到表情消息
  onEmojiMessage: function() {},
  // 收到图片消息
  onPictureMessage: function(message) {
    store.commit("Im/updateChatList", message);
  },
  // 收到命令消息
  onCmdMessage: function() {},
  //收到音频消息
  onAudioMessage: function(message) {
    window.localStorage.setItem("audioUrl", message.url);
    let options = {
      url: message.url,
      headers: {
        Accept: "audio/mp3",
      },
      onFileDownloadComplete: function(response) {
        // let objectUrl = WebIM.default.utils.parseDownloadResponse.call(WebIM.conn, response);
        var audio = document.createel;
        var audio = document.createElement("audio");
        document.body.appendChild(audio);
        audio.src = options.url;
        audio.controls = true;
      },
      // 音频下载失败
      onFileDownloadError: function() {},
    };
    WebIM.utils.download.call(WebIM.conn, options);
  },
  //收到位置消息
  onLocationMessage: function() {},
  //收到文件消息
  onFileMessage: function() {},
  //消息撤回
  recallMessage: function() {},
  //收到视频消息
  onVideoMessage: function(message) {
    var node = document.getElementById("getVideo");
    var option = {
      url: message.url,
      headers: {
        Accept: "audio/mp4",
      },
      onFileDownloadComplete: function(response) {
        var objectURL = WebIM.utils.parseDownloadResponse.call(conn, response);
        node.src = objectURL;
      },
      onFileDownloadError: function() {},
    };
    WebIM.utils.download.call(conn, option);
  },
  //处理“广播”或“发布-订阅”消息，如联系人订阅请求、处理群组、聊天室被踢解散等消息
  onPresence: function(message) {
    var myDate = new Date().toLocaleString();
    switch (message.type) {
      case "subscribe": // 对方请求添加好友
        var truthBeTold = window.confirm(message.from + "申请添加您为好友:");
        if (truthBeTold) {
          // 同意对方添加好友
          WebIM.conn.subscribed({
            to: message.from,
            message: "[resp:true]",
          });
        } else {
          // 拒绝对方添加好友
          WebIM.conn.unsubscribed({
            to: message.from,
            message: "rejectAddFriend", // 拒绝添加好友回复信息
          });
        }
        break;
      case "subscribed": // 对方同意添加好友，已方同意添加好友
        break;
      case "unsubscribe": // 对方删除好友
        break;
      case "unsubscribed": // 被拒绝添加好友，或被对方删除好友成功
        break;
      case "memberJoinPublicGroupSuccess": // 成功加入聊天室
        break;
      case "joinChatRoomFaild": // 加入聊天室失败
        break;
      case "joinPublicGroupSuccess": // 意义待查
        break;
      case "joinGroupNotifications": //收到申请加群通知
        var groupNotifications = window.confirm(
          (message.from + "申请加入群组: " + message, groupid)
        );
        if (groupNotifications) {
          // 同意申请
          var options = {
            applicant: message.from, // 申请加群的用户名
            groupId: message,
            groupid, // 群组ID
            success: function(res) {},
          };
          WebIM.conn.agreeJoinGroup(options);
        } else {
          // 拒绝申请
          var options = {
            applicant: message.from, // 申请加群的用户名
            groupId: message,
            groupid, // 群组ID
            success: function(res) {},
          };
          WebIM.conn.rejectJoinGroup(options);
        }
        break;
      case "direct_joined":
        break;
      case "createGroupACK":
        conn.createGroupAsync({
          from: message.from,
          success: function(option) {},
        });
        break;
    }
  },
  //处理好友申请
  onRoster: function(message) {
    for (var i = 0, l = message.length; i < l; i++) {
      var ros = message[i];
      if (ros.subscription === "both" || ros.subscription === "to") {
      }
    }
  },

  //处理群组邀请
  onInviteMessage: function(message) {},
  //本机网络连接成功
  onOnline: function() {},
  //本机网络掉线
  onOffline: function() {},
  //失败回调
  onError: function(data) {
    let { type, message } = data;
    switch (type) {
      case 16:
        // that.$message.error('服务端关闭了websocket链接~请尝试重新登录')
        // that.xin.goUrl('/login')
        break;
      case 8:
        // that.$message.error('多端登录，被踢下线')
        // that.xin.goUrl('/login')
        break;
      case 7:
        // this.$message.error('客户端网络中断 (net::ERR_INTERNET_DISCONNECTED)')
        break;
      case 17:
        // this.$message.error('客户端网络错误 (net::WEBIM_CONNCTION_AJAX_ERROR)')
        break;
    }
  },
  //黑名单变动(查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息)
  onBlacklistUpdate: function(list) {},
  //收到消息送达服务器回执
  onReceivedMessage: function(message) {},
  //收到消息送达客户端回执
  onDeliveredMessage: function(message) {},
  //收到消息已读回执
  onReadMessage: function(message) {},
  //创建群组成功回执（需调用createGroupNew）
  onCreateGroup: function(message) {},
  //如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
  onMutedMessage: function(message) {},
});

export default WebIM;
