export default {
  setUser(state, data) {
    let user = state.userInfo;
    for (let i in user) {
      if (typeof data[i] == "string") data[i] = data[i].trim();
      if (data[i]) user[i] = data[i];
    }
    state.userInfo = user;
  },
};
