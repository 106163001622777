export default [
  // 调研
  {
    name: "research",
    path: "/research",
    meta: {
      title: "调研",
      requireAuth: true,
    },
    component: () => import("@view/tabBar/research.vue"),
  },
  // 调研->我要推荐
  {
    name: "research-recommend-index",
    path: "/research-recommend-index",
    meta: {
      title: "我要推荐",
      requireAuth: true,
    },
    component: () => import("@view/research/recommend/index.vue"),
  },
  // 调研->电话调研
  {
    name: "research-questionnaireTelephone-index",
    path: "/research-questionnaireTelephone-index",
    meta: {
      title: "电话调研",
      requireAuth: true,
    },
    component: () => import("@view/research/questionnaireTelephone/index.vue"),
  },
  // 调研->电话调研->选择时间
  {
    name: "research-questionnaireTelephone-selectionPeriod",
    path: "/research-questionnaireTelephone-selectionPeriod",
    meta: {
      title: "选择时间",
      requireAuth: true,
    },
    component: () =>
      import("@view/research/questionnaireTelephone/selectionPeriod.vue"),
  },
  // 调研->面访调研
  {
    name: "research-interview-index",
    path: "/research-interview-index",
    meta: {
      title: "电话调研",
      requireAuth: true,
    },
    component: () => import("@view/research/interview/index.vue"),
  },
  // 调研->面访调研->参与成功
  {
    name: "research-interview-successfulParticipation",
    path: "/research-interview-successfulParticipation",
    meta: {
      title: "参与成功",
      requireAuth: true,
    },
    component: () =>
      import("@view/research/interview/successfulParticipation.vue"),
  },
  // 调研->支付
  {
    name: "research-pay-index",
    path: "/research-pay-index",
    meta: {
      title: "支付",
      requireAuth: true,
    },
    component: () => import("@view/research/pay/index.vue"),
  },
  // 调研->支付->问卷
  {
    name: "research-questionnaire-index",
    path: "/research-questionnaire-index",
    meta: {
      title: "问卷",
      requireAuth: true,
    },
    component: () => import("@view/research/questionnaire/index.vue"),
  },
  // 调研->支付->问卷->知情同意书
  {
    name: "research-questionnaire-informedConsent",
    path: "/research-questionnaire-informedConsent",
    meta: {
      title: "知情同意书",
      requireAuth: true,
    },
    component: () => import("@view/research/questionnaire/informedConsent.vue"),
  },
  // 调研->支付->问卷->知情同意书
  {
    name: "research-questionnaire-informedRule",
    path: "/research-questionnaire-informedRule",
    meta: {
      title: "知情同意书",
      requireAuth: true,
    },

    component: () => import("@view/research/questionnaire/informedRule.vue"),
  },
  // 调研->支付->问卷->知情同意书->答卷
  {
    name: "research-questionnaire-answerSheet",
    path: "/research-questionnaire-answerSheet",
    meta: {
      title: "答卷",
      requireAuth: true,
    },
    component: () => import("@view/research/questionnaire/answerSheet.vue"),
  },
  // 调研->支付->问卷->知情同意书->答卷结束
  {
    name: "research-questionnaire-endofQuestionnaire",
    path: "/research-questionnaire-endofQuestionnaire",
    meta: {
      title: "答卷结束",
    },
    component: () =>
      import("@view/research/questionnaire/endofQuestionnaire.vue"),
  },
];
