export default [
  {
    path: "/",
    redirect: {
      name: "index",
      path: "/index",
    },
  },
  // 首页
  {
    name: "index",
    path: "/index",
    meta: {
      keepAlive: true,
    },
    component: () => import("@view/tabBar/index.vue"),
  },
  // 首页->瓷片区
  {
    name: "index-porcelainArea",
    path: "/index-porcelainArea",
    component: () => import("@view/index/porcelainArea/index.vue"),
  },
  // 首页->新闻详情
  {
    name: "index-newsDetail",
    path: "/index-newsDetail",

    meta: {
      title: "新闻详情",
    },
    component: () => import("@view/index/newsDetail"),
  },

  // 首页->广告图文详情
  {
    name: "index-asdvertising",
    path: "/index-asdvertising",
    meta: {
      title: "新闻详情",
    },
    component: () => import("@view/index/asdvertising"),
  },
  // 首页->广告图文详情
  {
    name: "app",
    path: "/app",
    meta: {
      title: "新闻详情",
    },
    component: () => import("@view/index/app"),
  },

  // 首页->卫生法规
  {
    name: "index-examSettingPolicy",
    path: "/index-examSettingPolicy",
    meta: {
      title: "卫生法规",
    },
    component: () => import("@view/index/examSettingPolicy"),
  },
  // 首页->卫生法规->详情
  {
    name: "index-examSettingPolicy-info",
    path: "/index-examSettingPolicy-info",
    meta: {
      title: "卫生法规详情",
    },
    component: () => import("@view/index/examSettingPolicy/info"),
  },
  // 首页->考纲教材
  {
    name: "index-syllabus",
    path: "/index-syllabus",
    meta: {
      title: "考纲教材",
    },
    component: () => import("@view/index/syllabus"),
  },
  // 首页->考纲教材->考纲
  {
    name: "index-syllabus-syllabus",
    path: "/index-syllabus-syllabus",
    meta: {
      title: "考纲",
      requireAuth: true,
    },
    component: () => import("@view/index/syllabus/syllabus.vue"),
  },
  // 首页->考纲教材->视频讲座
  {
    name: "index-syllabus-videoLecture",
    path: "/index-syllabus-videoLecture",
    meta: {
      title: "视频讲座",
      requireAuth: true,
    },
    component: () => import("@view/index/syllabus/videoLecture.vue"),
  },
  // 首页->考纲教材->视频讲座->视频讲座详情
  {
    name: "index-syllabus-videoLectureDetail",
    path: "/index-syllabus-videoLectureDetail",
    meta: {
      title: "视频讲座详情",
      requireAuth: true,
    },
    component: () => import("@view/index/syllabus/videoLectureDetail.vue"),
  },
  // 首页->模拟考试
  {
    name: "index-mockExam",
    path: "/index-mockExam",
    meta: {
      title: "模拟考试",
      requireAuth: true,
    },
    component: () => import("@view/index/mockExam/index.vue"),
  },
  // 首页->模拟考试->模拟历史
  {
    name: "index-mockExam-simulationHistory",
    path: "/index-mockExam-simulationHistory",
    meta: {
      title: "模拟历史",
      requireAuth: true,
    },
    component: () => import("@view/index/mockExam/simulationHistory.vue"),
  },
  // 首页->模拟考试->模拟历史->模拟结果
  {
    name: "index-mockExam-simulationResult",
    path: "/index-mockExam-simulationResult",
    meta: {
      title: "模拟结果",
      requireAuth: true,
    },
    component: () => import("@view/index/mockExam/simulationResult.vue"),
  },
  // 首页->模拟考试->模拟卷->试卷
  {
    name: "index-mockExam-answerSheet",
    path: "/index-mockExam-answerSheet",
    meta: {
      title: "试卷",
      requireAuth: true,
    },
    component: () => import("@view/index/mockExam/answerSheet.vue"),
  },
  // 首页->在线学习
  {
    name: "index-eLearning",
    path: "/index-eLearning",
    meta: {
      title: "在线学习",
      requireAuth: true,
    },
    component: () => import("@view/index/eLearning/index.vue"),
  },
  // 首页->专家委员
  {
    name: "index-expertCommittee",
    path: "/index-expertCommittee",
    meta: {
      title: "专家委员",
      requireAuth: true,
    },
    component: () => import("@view/index/expertCommittee"),
  },
  // 首页->专家委员->医生详情
  {
    name: "index-expertCommittee-doctorDetails",
    path: "/index-expertCommittee-doctorDetails",
    meta: {
      title: "医生详情",
      requireAuth: true,
    },
    component: () => import("@view/index/expertCommittee/doctorDetails.vue"),
  },
  // 授权
  {
    name: "oauth",
    path: "/oauth",
    component: () => import("@view/index/oauth/oauth.vue"),
  },

  {
    name: "oauthRegister",
    path: "/oauthRegister",
    component: () => import("@view/index/oauth/oauthRegister.vue"),
  },
];
