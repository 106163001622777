export default [
  // 药品库
  {
    name: "drugs",
    path: "/drugs",
    meta: {
      title: "药品库",
      keepAlive: true,
      requireAuth: true,
    },
    component: () => import("@view/drugs/drugs.vue"),
  },
  // 药品列表
  {
    name: "drugsList",
    path: "/drugsList",
    meta: {
      title: "药品列表",
      requireAuth: true,
    },
    component: () => import("@view/drugs/drugsList.vue"),
  },

  // 药品详情页drugsDetail
  {
    name: "drugsDetail",
    path: "/drugsDetail",
    meta: {
      title: "药品详情",
    },
    component: () => import("@view/drugs/drugsDetail.vue"),
  },

  // 方剂
  {
    name: "recipe",
    path: "/recipe",
    meta: {
      title: "方剂",
      keepAlive: true,
      requireAuth: true,
    },
    component: () => import("@view/drugs/recipe.vue"),
  },

  // 方剂列表页
  {
    name: "recipeList",
    path: "/recipeList",
    meta: {
      title: "方剂列表",
      requireAuth: true,
    },
    component: () => import("@view/drugs/recipeList.vue"),
  },

  // 方剂详情
  {
    name: "recipeDetail",
    path: "/recipeDetail",
    meta: {
      title: "方剂详情",
    },
    component: () => import("@view/drugs/recipeDetail.vue"),
  },
];
