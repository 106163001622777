<template>
  <div>
    <el-dialog
      :visible.sync="isShowKefu"
      custom-class="mudialog"
      :modal-append-to-body="false"
    >
      <div class="kefu-main" v-loading="!loginFlag">
        <div class="header flex-alc flex-btn">
          <h2>{{ kefuName }}</h2>
          <img
            src="@image/guanbi.png"
            alt=""
            @click="isShowKefu = false"
            style="cursor: pointer"
          />
        </div>
        <div ref="chatMain" class="chat-main" v-loading="loadingFlag">
          <div class="content-box scroll" ref="scroll" v-loading="loadingFlag">
            <template v-for="item in chatData">
              <div
                :class="kfuserInfo.id != item.from ? 'flex-end' : 'flex-start'"
                class="child-left"
                :key="item.id"
              >
                <img
                  class="user-img"
                  v-if="kfuserInfo.id == item.from"
                  :src="kfuserInfo.avatar || defaultAvatr"
                  alt="头像"
                />
                <div class="user-box">
                  <div class="message-box">
                    <img
                      v-if="item.contentsType === 'IMAGE'"
                      :preview="item.from"
                      v-lazy="item.url"
                      alt="图片加载失败"
                    />
                    <pre
                      v-html="item.data ? sinaFace(item.data) : '空白消息'"
                      v-if="item.contentsType === 'TEXT'"
                    ></pre>
                    <!-- <pre
                      v-if="item.contentsType === 'CUSTOM'"
                      style="color: #999"
                    >[语音消息]
                    </pre> -->
                  </div>
                  <div class="time">{{ item.time | chatTime($moment) }}</div>
                  <div class="corner">
                    {{ kfuserInfo.id == item.from ? "◀" : "▶" }}
                  </div>
                </div>
                <img
                  class="user-img"
                  v-if="kfuserInfo.id != item.from"
                  :src="userInfo.avatar || defaultAvatr"
                  alt="头像"
                />
              </div>
            </template>

            <!-- 用户被动打分 -->
            <div class="passive_grade" v-show="is_passive_grade_show">
              <p class="grade_time">
                {{ Date.now().toString() | chatTime($moment) }}
              </p>
              <div class="child-left flex-start">
                <img :src="kfuserInfo.avatar" alt="头像" class="user-img" />
                <div class="user-box">
                  <div class="message-box">
                    请问还有其它关于网医的问题吗？如果没有，还希望您能点击小星星对我的本次服务做出评价，谢谢您的支持！
                  </div>
                  <div class="corner">◀</div>
                </div>
              </div>
              <div class="star">
                <span
                  @click="scoreStar(index)"
                  v-for="(item, index) in star"
                  :key="index"
                >
                  <img :src="item.src" alt="" />
                  {{ item.word }}
                </span>
              </div>
            </div>

            <!-- 用户主动打分 -->
            <div class="grade" v-show="is_grade_show">
              <p class="grade_time">
                {{ Date.now().toString() | chatTime($moment) }}
              </p>
              <p class="desc">您对{{ kefuName.split("\t")[1] }}的服务满意吗?</p>
              <div class="star">
                <span
                  @click="scoreStar(index)"
                  v-for="(item, index) in star"
                  :key="index"
                >
                  <img :src="item.src" alt="" />
                  {{ item.word }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomt-box">
          <div class="flower" @click="showGrade">
            <img src="@image/flower.png" alt="" />
            <span>评价服务</span>
          </div>
          <div class="emo">
            <img
              @click="toogleDialogEmoji"
              class="emoticon"
              src="@image/emoticon.png"
              alt=""
            />
            <VEmojiPicker
              v-show="showDialog"
              :style="{ width: '440px', height: '200' }"
              labelSearch="Search"
              lang="pt-BR"
              @select="onSelectEmoji"
            />
            <label>
              <img
                class="upload_pictures"
                src="@image/upload_pictures.png"
                alt=""
              />
              <input
                id="image"
                @click="upload_pictures()"
                @input="getPictures($event)"
                accept="image/*"
                type="file"
              />
            </label>
          </div>
          <div class="input-box">
            <textarea
              ref="textarea"
              v-model="message"
              class="scroll"
              name=""
              id=""
              cols="30"
              rows="10"
              @keyup="sendEnter"
              placeholder="请输入内容"
            ></textarea>
          </div>
          <div class="send" @click="sendMsg">发送</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
import { emoticon } from "@/utils/face";

export default {
  name: "kefu",
  components: {
    VEmojiPicker,
  },

  data() {
    return {
      kefuName: "",
      message: "", //消息内容
      kfuserInfo: {
        avatar: this.$http.baseURL + "/static/images/web/avater.jpg",
        name: "健康通客服",
        id: "jkt",
      },
      defaultAvatr:
        this.$http.baseURL + "/static/images/web/247383b65abc2728.png", // 默认头像
      loadingFlag: false,
      chatData: [],
      isShowKefu: false,
      showDialog: false,
      kefuId: "",
      chat_id: "",
      star: [
        {
          src: require("@image/star.png"),
          word: "很不满意",
        },
        {
          src: require("@image/star.png"),
          word: "不满意",
        },
        {
          src: require("@image/star.png"),
          word: "一般",
        },
        {
          src: require("@image/star.png"),
          word: "满意",
        },
        {
          src: require("@image/star.png"),
          word: "很满意",
        },
      ],
      is_grade_show: false, // 用户主动评分弹框
      is_passive_grade_show: false, // 用户被动评分弹框
    };
  },

  computed: {
    ...mapState("Im", ["appkey", "ImUserData", "loginFlag"]),
    ...mapState("userManagement", ["userInfo"]),
  },

  mounted() {
    if (this.loginFlag) {
      this.getChatData();
      this.getPictures();
    }
    this.updateOwnUserInfo();
  },

  filters: {
    chatTime(val, moment) {
      let date = new Date(parseInt(val)).getTime();
      let nowData = Date.now();
      if (date > nowData + 10 * 60 * 1000) {
        // 3 分钟内不显示时间
        return moment(date).calendar();
        // return ''
      } else {
        return moment(date).calendar();
      }
    },
  },

  watch: {
    // 登录
    loginFlag(val) {
      if (val) {
        this.getChatData();
        this.getPictures();
      }
    },
    // 聊天数据
    chatData(val) {
      this.$previewRefresh();
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs) {
            if (val.length > 0) {
              var container = this.$refs.scroll;
              container.scrollTop = container.scrollHeight;
            }
          }
        });
      }, 300);
    },

    // 客服框不显示时,隐藏评分框
    isShowKefu(val) {
      if (val == false) {
        this.is_grade_show = false;
        this.is_passive_grade_show = false;
      }
    },
  },

  methods: {
    // 解析新浪表情
    sinaFace(data) {
      let reg = /\[([^\[\]]+)\]/; // 匹配中括号包含中括号里面的内容
      if (reg.test(data)) {
        data.match(/\[([^\[\]]+)\]/g).forEach((item1) => {
          emoticon.forEach((item2) => {
            if (item1 == item2.phrase) {
              item1 = "<img src=" + item2.url + ' height="22" width="22"">';
              data = data.replace(/\[([^\[\]]+)\]/, item1);
            }
          });
        });
      }
      return data;
    },

    // im用户属性
    updateOwnUserInfo() {
      let title = "";
      let hospital = "";
      let department = "";
      if (this.userInfo.company) {
        title = this.userInfo.company.title_name;
        hospital = this.userInfo.company.company;
        department = this.userInfo.company.department_name;
      }

      let options = {
        nickname: this.userInfo.nickname,
        avatarurl: this.userInfo.avatar,
        phone: this.userInfo.phone,
        ext: JSON.stringify({
          nickname: this.userInfo.nickname,
          avatarurl: this.userInfo.avatar,
          phone: this.userInfo.phone,
          title,
          hospital,
          department,
        }),
      };
      WebIM.conn.updateOwnUserInfo(options).then((res) => {});
    },

    // 获取聊天记录  -- 云端
    getChatData(idC) {
      WebIM.conn.mr_cache = [];
      this.chatData = [];
      this.kefuId = idC ? idC.toLowerCase() : "";
      this.kfuserInfo.id = this.kefuId;
      this.loadingFlag = true;
      let options = {
        queue: idC,
        isGroup: false,
        count: 100,
        success: (res) => {
          this.loadingFlag = false;
          this.chatData = res.filter((item) => {
            return !item.action;
          });
          this.greet();
          this.ten_minutes();
        },
        fail: () => {
          this.loadingFlag = false;
        },
      };
      setTimeout(() => {
        this.greet();
      });
      this.loadingFlag = false;
      WebIM.conn.fetchHistoryMessages(options);

      // 接收消息
      WebIM.conn.listen({
        // 接收命令消息(用户被动评级)
        onCmdMessage: (message) => {
          if (message && !this.is_passive_grade_show && !this.is_grade_show) {
            this.is_passive_grade_show = true;
            this.toBottom();
          }
        },
        //收到文本消息
        onTextMessage: (message) => {
          this.chatData.push(message);
        },
        //收到图片消息
        onPictureMessage: (message) => {
          this.chatData.push(message);
        },
      });
    },

    // 发送按钮发送文本消息
    sendMsg() {
      this.showDialog = false;
      //发送信息
      this.kfuserInfo.id = this.kefuId;
      let { message } = this;
      if (!message.trim()) {
        this.$message("消息内容不能为空");
        return;
      }
      let dataNow = Date.now();
      let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
      let msg = new this.$WebIM.message("txt", id); // 创建文本消息
      message.replace(/[\r\n]/g, "").trim();
      msg.set({
        msg: message, // 消息内容
        from: this.userInfo.id,
        to: this.kfuserInfo.id, // 接收消息对象（用户id）
        chatType: "singleChat", // 设置为单聊
        success: (id) => {
          let obj = {
            id: id,
            contentsType: "TEXT",
            time: dataNow.toString(),
            from: this.userInfo.id,
            to: this.kfuserInfo.id,
            message_type: "chat",
            data: message,
          };
          this.chatData.push(obj);
          this.message = "";
        },
        fail: (err) => {
          this.$message.error("发送失败" + JSON.stringify(err));
        },
      });
      // 发送文本
      setTimeout(() => {
        if (this.isAltEnter) {
          //如果为阻塞状态就反转状态
          this.isAltEnter = false;
        } else {
          //如果不是就执行发送
          this.$WebIM.conn.send(msg.body);
        }
      }, 100);
    },

    // enter发送
    sendEnter(e) {
      if (e.keyCode === 13 && e.ctrlKey) {
        this.message += "\n"; //换行
      } else if (e.keyCode === 13) {
        this.sendMsg(); //提交的执行函数
        e.preventDefault(); //禁止回车的默认换行
        this.message = "";
      }
    },

    // 表情包
    toogleDialogEmoji() {
      this.showDialog = !this.showDialog;
    },

    // 选择表情
    onSelectEmoji(emoji) {
      this.message += emoji.data;
      this.$refs.textarea.focus();
    },

    // 上传图片
    upload_pictures() {
      this.showDialog = false;
    },

    // web单聊发送图片消息
    getPictures() {
      this.showDialog = false;
      this.kfuserInfo.id = this.kefuId;
      let dataNow = Date.now();
      var id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
      var msg = new this.$WebIM.message("img", id); // 创建图片消息
      var input = document.getElementById("image"); // 选择图片的input
      var file = this.$WebIM.utils.getFileUrl(input); // 将图片转化为二进制文件
      var allowType = {
        jpg: true,
        gif: true,
        png: true,
        bmp: true,
      };
      if (file.filetype.toLowerCase() in allowType) {
        var option = {
          file: file,
          ext: {
            file_length: file.data.size, // 文件大小
          },
          to: this.kfuserInfo.id, // 接收消息对象
          chatType: "singleChat", // 设置为单聊
          success: (id) => {
            let obj = {
              id: id,
              contentsType: "IMAGE",
              time: dataNow.toString(),
              from: this.userInfo.id,
              to: this.kfuserInfo.id,
              message_type: "chat",
              url: file.url,
            };
            this.chatData.push(obj);
          },
          fail: (err) => {
            this.$message.error("发送失败" + JSON.stringify(err));
          },
          flashUpload: this.$WebIM.flashUpload,
        };
        msg.set(option);
        this.$WebIM.conn.send(msg.body);
      }
    },

    // 在医生联系访问员时，访问员的自动弹框
    greet() {
      let obj = {
        contentsType: "TEXT",
        data: "您好，我是您的专属客服，" + this.kefuName + "为您服务",
        from: this.kefuId,
        id: Date.now(),
        message_type: "chat",
        time: Date.now().toString(),
      };
      this.chatData.push(obj);
    },

    // 评分五角星
    async scoreStar(index) {
      // 五角星的显示
      this.star.forEach((item, ind) => {
        if (ind <= index) {
          item.src = require("@image/stared.png");
        } else {
          item.src = require("@image/star.png");
        }
      });
      // 评分
      let res = await this.$loginApi.setChatScore({
        fraction: index,
        chat_id: this.chat_id,
      });
      if (res.code == 200) {
        this.$message.success(res.message);
        setTimeout(() => {
          this.is_grade_show = false;
          this.is_passive_grade_show = false;
        }, 1000);
      } else {
        this.$message.success(res.message);
      }
    },

    // 用户主动评价
    async showGrade() {
      // 判断有没有聊天记录
      if (this.chatData.length < 2) {
        this.$message("没有咨询过的客服不可以评价");
        return;
      }
      if (!this.is_grade_show) {
        // 验证24小时接口
        let res = await this.$loginApi.verifyIsScore({
          chat_id: this.chat_id,
        });
        if (res.code == 200) {
          this.is_grade_show = true;
          this.toBottom();
        } else {
          this.$message(res.message);
        }
      }
    },

    // 24小时内没有评分并且10分钟内没有对话 自动弹出评分框
    async ten_minutes() {
      let res = await this.$loginApi.verifyIsScore({
        chat_id: this.chat_id,
      });
      if (res.code == 200 && !this.is_grade_show) {
        let nowTime = +new Date(); // 当前时间
        let lastTime = this.chatData[this.chatData.length - 2].time; // 最后的通话时间
        let interva = nowTime - lastTime; // 时间差
        if (interva > 600000) {
          this.is_grade_show = true;
          this.toBottom();
        }
      }
    },

    // 滚动到底部
    toBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
          var container = this.$refs.scroll;
          container.scrollTop = container.scrollHeight;
        });
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/css/themeColor.scss";

/deep/.el-dialog__wrapper {
  z-index: 1000 !important;
}

/deep/.v-modal {
  z-index: 900 !important;
}

.w-100 {
  width: 100%;
}

$myColor: #1b334d;
$viceColor: #b02222;

@mixin flex {
  display: flex;
}

.wid {
  width: 690rpx;
  margin-left: auto;
  margin-right: auto;
}

.xin-flex {
  @include flex();
}

.flex-btn {
  justify-content: space-between;
  @include flex();
}

.flex-btn {
  justify-content: space-between;
  @include flex();
}

.flex-alc {
  align-items: center;
  @include flex();
}

.text-center {
  text-align: center;
}

.block {
  display: block;
}

.h1 {
  font-size: 40rpx;
}

.h2 {
  font-size: 36rpx;
}

.h3 {
  font-size: 32rpx;
}

.h4 {
  font-size: 28rpx;
}

.h5 {
  font-size: 24rpx;
}

.h6 {
  font-size: 20rpx;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.scroll {
  //自定义滚动条样式
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(64, 158, 255, 0.1);
    background: #5353535e;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(64, 158, 255, 0.1);
    border-radius: 10px;
    background: #ededed;
  }
}

/deep/.mudialog {
  border-radius: 30px !important;
}

.kefu-main {
  padding: 20px;

  .header {
    h2 {
      font-size: 26px;
      font-weight: 500;
      color: $--themeColor;
      line-height: 50px;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .content-box {
    height: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 20px 0;
    flex: 1;
    padding: 0 20px 30px 0;

    img {
      object-fit: cover;
      cursor: pointer;
    }

    .flex-end {
      //flex-direction: row-reverse;
      justify-content: flex-end;

      .user-box {
        align-items: flex-end;
        margin-right: 30px;

        .message-box {
          background: #03dbc6 !important;
        }
      }

      .corner {
        right: 0px;
        transform: translate(12px, 10px) !important;
        left: unset !important;
      }
    }

    .child-left {
      display: flex;
      margin: 20px 0;

      .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #a5b5c1;
      }

      .user-box {
        position: relative;
        margin: 0 15px;
        display: flex;
        flex-direction: column;

        .message-box {
          background-color: #dbfbf8;
          border-radius: 10px;
          line-height: 1.8;
          font-size: 18px;
          position: relative;
          max-width: 350px;
          width: max-content;
          word-wrap: break-word;
          word-break: break-all;
          padding: 10px 20px;
          overflow: hidden;

          pre {
            white-space: pre-wrap;
          }

          img {
            max-width: 250px;
          }
        }

        .time {
          margin-top: 10px;
          color: #a5b5c1;
          font-size: 12px;
        }

        .corner {
          position: absolute;
          top: 0px;
          color: #dbfbf8;
          left: 0px;
          transform: translate(-12px, 10px);
        }
      }
    }
  }

  .bottomt-box {
    background-color: #fff;
    border-top: 1px solid #f2f2f2;
    height: 130px;
    padding: 3px 20px 20px 10px;
    margin-top: 10px;
    position: relative;

    > .flower {
      cursor: pointer;
      position: absolute;
      font-size: 12px;
      width: 80px;
      height: 21px;
      border-radius: 30px;
      top: -30px;
      text-align: center;
      color: #fff;

      > img {
        width: 80px;
        height: 21px;
        vertical-align: sub;
      }

      span {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
      }
    }

    > .emo {
      height: 30px;
      display: flex;
      align-items: center;
      position: relative;

      > #EmojiPicker {
        position: absolute;
        bottom: 35px;
        left: 0;
        background: #fff;

        /deep/ #Categories {
          background: #fff;
        }

        /deep/ #InputSearch {
          display: none;
        }

        /deep/ #Emojis {
          height: 200px;
        }
      }

      > .emoticon {
        width: 20px;
        margin-right: 15px;
        cursor: pointer;
      }

      > label {
        cursor: pointer;

        .upload_pictures {
          width: 20px;
          vertical-align: middle;
          margin-right: 10px;
        }

        > input {
          display: none;
        }
      }
    }
  }

  .input-box {
    height: 80px;

    textarea {
      border: none;
      width: 100%;
      height: 80px;
      font-size: 16px;
      outline: none;
      resize: none;
    }
  }

  .send {
    cursor: pointer;
    position: absolute;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
    width: 100px;
    right: 0;
    bottom: 0;
    color: $--themeColor;
  }
}

// 客服被动评分
.passive_grade {
  text-align: center;
  .grade_time {
    color: #b8b8b8;
  }

  .message-box {
    text-align: left;
  }

  .child-left {
    margin: 10px 0 !important;
  }

  .star {
    width: 350px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    margin-left: 70px;

    span {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 10px;
      cursor: pointer;
      text-align: center;
      font-size: 12px;
      color: #b1b1b1;

      img {
        width: 40px;
        margin-bottom: 10px;
      }
    }
  }
}

// 客服主动评分
.grade {
  text-align: center;

  .grade_time {
    color: #b8b8b8;
    padding: 10px 0;
  }

  .desc {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    position: relative;
    color: #b1b1b1;

    &::after {
      position: absolute;
      left: 15%;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 17%;
      height: 1px;
      background: #b8b8b8;
    }
    &::before {
      position: absolute;
      right: 15%;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 17%;
      height: 1px;
      background: #b8b8b8;
    }
  }

  .star {
    padding: 10px;
    display: flex;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    opacity: 0.71;
    border-radius: 51px;
    width: 75%;
    margin: 0 auto;
    margin-top: 10px;
    min-width: 350px;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: pointer;
      font-size: 12px;

      img {
        width: 40px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>


<style>
.pswp__top-bar {
  top: 20px !important;
  padding: 0 100px;
}
.pswp__button--fs {
  display: none !important;
}
</style>

