import Vue from "vue";
import VueRouter from "vue-router";
const _this = Vue.prototype;
import indexPath from "./index";
import foundPath from "./found";
import creationPath from "./creation";
import researchPath from "./research";
import newsPath from "./news";
import searchFor from "./searchFor";
import drugsPath from "./drugs";
import liveBroadcastPath from "./liveBroadcast";
import commonPath from "./common";

Vue.use(VueRouter);

// 解决相同路径跳转报错(NavigationDuplicated)
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(() =>
    VueRouterPush.call(this, { path: "/refresh" })
  );
};

// 路由规则
const routes = [
  // 首页相关页面
  ...indexPath,
  // 发现相关页面
  ...foundPath,
  // 创作相关页面
  ...creationPath,
  // 调研相关页面
  ...researchPath,
  // 基本页面
  ...commonPath,
  // 消息页面
  ...newsPath,
  // 搜索页面
  ...searchFor,
  // 药品库页面
  ...drugsPath,
  // 直播页面
  ...liveBroadcastPath,
  //404
  {
    path: "/*",
    name: "404页面丢失了",
    component: () => import("@view/tabBar/404.vue"),
  },
];

// 初始化路由
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  // 所有页面点击返回时候显示在页面最顶端
  scrollBehavior() {
    return { x: 0, y: -800 };
  },
});

// 添加路由守卫
router.beforeEach((to, from, next) => {
  // 判断该路由是否需要登录权限
  if (to.meta.requireAuth) {
    // 判断是否在登录状态
    let token = localStorage.getItem("token");
    if (
      token ||
      to.fullPath == "/setting?index=2" ||
      to.fullPath == "/setting?index=3" ||
      to.fullPath == "/settingTwo"
    ) {
      next();
    } else {
      // 拦截到首页
      next({ path: "/index" });
      _this.bus.$emit("click", true);
      _this.xin.message("请先登录", "err");
    }
  } else {
    next();
  }
});

// 后置路由守卫
router.afterEach((to) => {
  document.title = to?.meta?.title ? `网医-${to.meta.title}` : `网医`;
  window.scrollTo(0, 0);
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
});

export default router;
