import http from "@/utils/api/http";

export default {
  // 首页新闻列表
  getNewsLists(data) {
    return http.post("/dkt/news/lists", data);
  },
  // 首页新闻详情
  getNewsInfo(data) {
    return http.post("/dkt/news/news_info", data);
  },
  //首页图文
  getHomeGet_home(data) {
    return http.get("/dkt/home/get_home", data);
  },
  //新闻点赞或者评论点赞
  add_action(data) {
    return http.post("/dkt/news/add_action", data);
  },
  //职业等级
  getDocterLevel(data) {
    return http.post("/dkt/docter/level", data);
  },
  // 认证科室接口
  getDocterDepartment(data) {
    // return http.post("/dkt/docter/department", data);
    return http.post("/dkt/docter/book_department", data);
  },
  //认证职务职称专业
  getDocterPosition(data) {
    return http.post("/dkt/docter/position", data);
  },
  //身份认证接口
  getDocterAdd(data) {
    return http.post("/dkt/docter/add", data);
  },
  //获取身份认证接口
  getDocterInfo(data) {
    return http.post("/dkt/docter/info", data);
  },
  //修改身份认证
  getDocterUpdate(data) {
    return http.post("/dkt/docter/update", data);
  },
  //所在科室
  get_department(data) {
    return http.post("/dkt/department/get_department", data);
  },
  // 省市区
  areaNew(data) {
    return http.post("/dkt/hospital/area", data);
  },

  // 判断是否绑定微信
  userInfo(data) {
    return http.post("/dkt/user/info", data);
  },

  hospital_list(data) {
    return http.post("/dkt/hospital/hospital_list", data);
  },

  createQRCode(data) {
    return http.post("/dkt/login/createQRCode", data);
  },

  open_bind(data) {
    return http.post("/dkt/login/open_bind", data);
  },

  // 修改认证
  updateCertification(data) {
    return http.post("/dkt/docter/update_certification", data);
  },
  // 添加认证
  addCertification(data) {
    return http.post("/dkt/docter/add_certification", data);
  },
  // 修改认证
  infoCertification(data) {
    return http.post("/dkt/docter/info_certification", data);
  },

  // 书籍列表
  getBookList(data) {
    return http.post("/dkt/book/lists", data);
  },
  //热点新闻
  getNewsBrowse(data) {
    return http.post("/dkt/news/browse", data);
  },
  //认证科室列表
  new_comment(data) {
    return http.post("/dkt/news/comment", data);
  },
  //身份认证接口
  getDoctor(data) {
    return http.post("/dkt/docter/add", data);
  },
  //上传文件
  getUpload(data) {
    return http.post("/dkt/other/upload", data);
  },
  //视频讲座
  getBookVideoLists(data) {
    return http.post("/dkt/book/videoLists", data);
  },
  //学习资料
  getStudyData(data) {
    return http.post("/dkt/study/studyData", data);
  },
  //书籍结构列表
  getBookCatalog(data) {
    return http.post("/dkt/study/bookCatalog", data);
  },
  //认证科室书籍接口
  getBook_department(data) {
    return http.post("/dkt/docter/book_department", data);
  },

  //
  getHome(data) {
    return http.get("/dkt/home/get_home", data);
  },

  // 卫生法规详情
  getHomeInfo(data) {
    return http.post("/dkt/home/home_info", data);
  },
  //试卷列表
  getExamLists(data) {
    return http.post("/dkt/exam/examLists", data);
  },
  //我的模拟
  getsimulationLists(data) {
    return http.post("/dkt/exam/simulation", data);
  },
  //添加书籍
  getAddBook(data) {
    return http.post("/dkt/book/addBook", data);
  },
  //书籍详情
  getBookInfo(data) {
    return http.post("/dkt/study/bookInfo", data);
  },
  //学习轨迹
  getStudyMark(data) {
    return http.post("/dkt/study/studyMark", data);
  },
  //添加学习时长
  addStudyMark(data) {
    return http.post("/dkt/study/addStudyMark", data);
  },
  //考卷详情
  getExamInfo(data) {
    return http.post("/dkt/exam/examInfo", data);
  },
  //交卷
  addExam(data) {
    return http.post("/dkt/exam/addExam", data);
  },

  //按照模块位置获取
  getBanner(data) {
    return http.get("/dkt/home/module", data);
  },
  //专家委员分类列表
  getExpert_typeList(data) {
    return http.post("/dkt/news/expert_type", data);
  },
  //二级专家委员分类列表
  getTwo_expert_typeList(data) {
    return http.post("/dkt/news/two_expert_type", data);
  },
  //专家委员列表
  getExpertList(data) {
    return http.post("/dkt/news/expert", data);
  },
  //专家委员详情
  getExpert_info(data) {
    return http.post("/dkt/news/expert_info", data);
  },
  //视频详情
  getBookVideoInfo(data) {
    return http.post("/dkt/book/videoInfo", data);
  },
  //历史记录
  getExamHistory(data) {
    return http.post("/dkt/exam/history", data);
  },
  //获取身份认证
  getDocter(data) {
    return http.post("/dkt/docter/info", data);
  },
  //修改身份认证接口
  getUpdateInfo(data) {
    return http.post("/dkt/docter/update", data);
  },
  //首页视频
  getnewsVideo(data) {
    return http.post("/dkt/news/video", data);
  },
  //按照浏览量视频排行
  getVideo_hot(data) {
    return http.post("/dkt/book/video_hot", data);
  },
  //热点新闻
  getNewsHot(data) {
    return http.post("/dkt/news/heat_lists", data);
  },
  //判断书籍是否在个人书库中
  is_user_book(data) {
    return http.post("/dkt/book/is_user_book", data);
  },
  //点赞
  findCommentLike(data) {
    return http.post("/dkt/discover/commentLike", data);
  },
  // 收藏
  findCollection(data) {
    return http.post("/dkt/discover/collection", data);
  },
  // 分享
  findShare(data) {
    return http.post("/dkt/discover/forward", data);
  },
  // 省市区
  getArea(data) {
    return http.post("/dkt/docter/area ", data);
  },

  // 新闻评论删除
  delNewComment(data) {
    return http.post("/dkt/news/del_comment ", data);
  },
};
