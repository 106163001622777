// 正则匹配除了index.js文件的其他所有文件
const modulesFiles = require.context("./", false, /[^xyz]\.js/);

const unit = modulesFiles.keys().reduce((module, modulePath) => {
  const moduleName = modulePath.replace(/\.\/(.*)\.js/, "$1");
  const value = modulesFiles(modulePath);
  module[moduleName] = value.default;
  return module;
}, {});

export default unit;
