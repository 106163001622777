import http from "@/utils/api/http";
export default {
  // 在线直播 - 直播列表
  liveList(data) {
    return http.post("/dkt/live/list", data);
  },

  // 加入直播间
  liveJoin(data) {
    return http.post("/dkt/live/join", data);
  },

  // 直播间计时
  liveKeep(data) {
    return http.post("/dkt/live/keep", data);
  },

  // 获取播放端用户签名
  getUserSig(data) {
    return http.post("/dkt/live/getUserSig", data);
  },
};
