import http from "@/utils/api/http";

export default {
  //个人中心列表
  //获取用户信息
  getUserInfo(data) {
    return http.post("/dkt/user/info", data);
  },

  // 收款记录
  getSurveyReward(data) {
    return http.post("/dkt/survey/reward", data);
  },

  // 收款记录分页
  getSurveyRewardList(data) {
    return http.post("/dkt/survey/reward_list", data);
  },

  // 我的收益列表
  getSurveyProfit(data) {
    return http.post("/dkt/survey/profit", data);
  },

  //获取我的收益(接口废弃)
  // getPersonalProfit(data) {
  //   return http.post("/dkt/personal/profit", data);
  // },

  //诊疗日志
  getOperationList(data) {
    return http.post("/dkt/operation/lists", data);
  },
  //我的创作
  getPersonalCreate(data) {
    return http.post("/dkt/personal/create", data);
  },
  //删除创作
  getDelDiscover(data) {
    return http.post("/dkt/discover/delDiscover", data);
  },
  //获取我的粉丝
  getFans(data) {
    return http.post("/dkt/personal/fans", data);
  },
  //获取我的关注
  getFollow(data) {
    return http.post("/dkt/personal/follow", data);
  },

  // 我的信誉分
  getPointLists(data) {
    return http.post("/dkt/personal/point_lists", data);
  },
};
