// 入口文件(全局引入)
import Vue from "vue";
import store from "@/store/index.js";
import router from "@/router/router.js";
import App from "@/App.vue";
import "@/utils";

// 全局引入css
import "@assets/css/main.scss";

// 阻止启动生产消息
Vue.config.productionTip = false;

// 公共bus事件总线
Vue.prototype.bus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
