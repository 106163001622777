/** @format */
import Vue from "vue";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);
class Loading {
  static loading;
  constructor() {}
  show(options = {}) {
    this.loading = Element.Loading.service({
      lock: true,
      customClass: "customLoading",
      background: "rgba(255, 255, 255, 0.9)",
      ...options,
    });
  }
  hide() {
    this.loading.close();
  }
}
Vue.prototype.$loading = new Loading();
