<template>
  <div class="field" :class="{ topBorder }">
    <span
      v-if="label !== false"
      class="label"
      :class="{ gray: type == 'switch' && !value }"
      >{{ label }}</span
    >
    <div style="flex: 1"></div>
    <template v-if="type === false">
      <slot name="default"></slot>
    </template>
    <!-- switch类型，显示开关按钮 -->
    <template v-else-if="type === 'switch'">
      <div
        class="value switch"
        :class="{ true: value }"
        @click="$emit('change', !value)"
      ></div>
    </template>
    <!-- select类型，显示选择框 -->
    <template v-else-if="type === 'select'">
      <el-dropdown trigger="click" @command="$emit('change', $event)">
        <span class="select" :class="{ active: value != null }"
          >{{
            value != null
              ? options.find((obj) => obj.value == value).label
              : placeholder
          }}<i class="iconfont icon_2"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in options"
            :key="index"
            :command="item.value"
            >{{ item.label }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <!-- radio类型，显示单选 -->
    <template v-else-if="type === 'radio'">
      <div
        class="radioItem"
        v-for="(item, index) in options"
        :key="index"
        @click="$emit('change', item.value)"
      >
        <span>{{ item.label }}</span>
        <i
          class="iconfont"
          :class="
            item.value == value ? 'zhanghu-xuanzhong' : 'zhanghu-weixuanzhong'
          "
        ></i>
      </div>
    </template>
    <!-- 其余类型显示普通输入框 -->
    <template v-else>
      <input
        autocomplete="off"
        class="value"
        :type="type"
        :readonly="read"
        :value="value"
        :placeholder="placeholder"
        @input="$emit('change', $event.target.value)"
      />
    </template>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    read: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number, Boolean],
      default: false,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    placeholder: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: [String, Boolean],
      default: false,
    },
    options: {
      type: [Array, Boolean],
      default: false,
    },
    topBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.field {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px solid #f2f2f2;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  &.topBorder {
    border-top: 1px solid #f2f2f2;
  }
  .gray {
    color: #8f8f8f;
  }
  > .value {
    font-size: 16px;
    text-align: right;
    border: none;
    background: none;
    color: #333333;
    &:focus {
      outline-color: transparent;
    }
    &::placeholder {
      color: #8f8f8f;
    }
    &.switch {
      cursor: pointer;
      box-sizing: border-box;
      // border: 2px solid #E6E6E6;
      width: 54px;
      height: 34px;
      border-radius: 34px;
      background-color: #e6e6e6;
      transition: 0.3s;
      display: flex;
      align-items: center;
      &.true {
        background-color: $--themeColor;
        // border-color: #03DBC6;
        &::after {
          left: calc(100% - 32px);
        }
      }
      &::after {
        position: absolute;
        transition: 0.3s;
        left: 2px;
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: white;
      }
    }
  }
  .select {
    &.active {
      color: #333333;
    }
    color: #8f8f8f;
    cursor: pointer;
    font-size: 16px;
    .icon_2 {
      margin-left: 6px;
      transition: 0.3s;
      font-size: 18px;
    }
  }
  .radioItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      margin-left: 54px;
      font-size: 16px;
      color: #8f8f8f;
    }
    > .iconfont {
      margin-left: 5px;
      color: $--themeColor;
      font-size: 16px;
    }
  }
}
::v-deep {
  .el-input__inner {
    border: none;
  }
}
</style>
